/* eslint-disable camelcase */
import React, { Component } from 'react';
import {
  Player,
  BigPlayButton,
  ControlBar,
  PlaybackRateMenuButton,
  ForwardControl
} from 'video-react';
import CustomLoading from '../../../util_components/custom_loading';
import { get_api_url } from '../../../../utils/urls';
import { get_api, post_api } from '../../../../redux/api_funcs';
import './cr.css';

export default class CropRecording extends Component {
  state = {
    loading: true,
    loaded: false,
    replay_url: '',
    start_time: -1,
    end_time: -1,
    times_list: [],
    recording_available: false,
    recording_status: '',
    timeRange: [],
    reloading_crop: false,
    recording_type: ''
  };

  playerRef = React.createRef;

  componentDidMount() {
    try {
      const { session_uuid, recording_type } = this.props.history.location.state;
      const replay_url = decodeURIComponent(this.props.history.location.state.replay_url);

      console.log(
        'session details',
        this.props.history.location,
        this.props.history.location.state
      );

      if (recording_type === 'speaker') {
        this.fetchCropReco(session_uuid);
      }

      this.setState({ replay_url, session_uuid, recording_type }, () => this.load_data());
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  load_data = () => {
    const { session_uuid } = this.state;
    const url = get_api_url('get_recording_crop_timing');
    const payload = { session_uuid };
    post_api(url, payload, true)
      .then((res) => {
        const replay_url = res.data.results.original_replay_url;
        const times_list = res.data.results.crop_timing;
        const new_url = get_api_url('get_video_recording_status');
        const new_payload = { session_uuid };
        this.setState({
          recording_available: true,
          loaded: true,
          replay_url,
          times_list,
          start_time: -1,
          end_time: -1
        });
        post_api(new_url, new_payload, true)
          .then((resp) => {
            this.setState({ loading: false, recording_status: resp.data.video_recording_status });
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false });
          });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false, recording_available: false, loaded: true });
      });
  };

  log_time = () => {
    try {
      const { start_time, end_time } = this.state;
      const times_list = [...this.state.times_list];
      const { player } = this.player.getState();
      let curr_time = Math.round(player.currentTime);
      curr_time = new Date(curr_time * 1000).toISOString().substr(11, 8);
      if (start_time !== -1 && end_time !== -1) {
        if (curr_time !== end_time) {
          times_list.push({ start_time, end_time });
          this.setState({ times_list, start_time: curr_time, end_time: -1 });
        }
      } else if (start_time !== -1) {
        if (curr_time !== start_time) {
          this.setState({ end_time: curr_time });
        }
      } else {
        this.setState({ start_time: curr_time });
      }
    } catch (e) {
      console.log(e);
    }
  };

  delete_row = (index) => {
    const { times_list } = this.state;
    const updated_list = [...times_list.slice(0, index), ...times_list.slice(index + 1)];
    this.setState({ times_list: updated_list });
  };

  remove_dates = () => this.setState({ start_time: -1, end_time: -1 });

  fetchCropReco = async (sessionUuid) => {
    const url = get_api_url(`/v2/crons-tasks/poc/session/${sessionUuid}/cropreco`, false, true);
    const response = await get_api(url, true);
    const timeRange = response?.data?.data.time_range || [];
    this.setState({ timeRange });
  };

  regenAICrop = async () => {
    this.setState({ reloading_crop: true });

    const { session_uuid } = this.state;

    const url = get_api_url(
      `/v2/crons-tasks/event_resync_session_crop?suid=${session_uuid}`,
      false,
      true
    );
    await get_api(url, true);
    await this.fetchCropReco(session_uuid);
    this.setState({ reloading_crop: false });
  };

  render_logs = () => {
    const { start_time, end_time, times_list, timeRange, reloading_crop, recording_type } =
      this.state;

    return (
      <div>
        {recording_type === 'speaker' && (
          <div>
            {reloading_crop ? (
              <p style={{ textAlign: 'center' }}>Regenerating crop suggestion...</p>
            ) : (
              <button onClick={this.regenAICrop}>Regen AI crop</button>
            )}
          </div>
        )}

        {!reloading_crop && timeRange.length > 0 && (
          <>
            <p style={{ textAlign: 'center' }}>AI suggested crop times</p>
            <div className="cus-table">
              <div className="ct-row ct-h">
                <div className="ct-col ct-sm-font">Start time</div>
                <div className="ct-col ct-sm-font">End time</div>
              </div>
              {timeRange.map((l, index) => (
                <div className="ct-row" key={`${l.title}-${index}`}>
                  <div className="ct-col ct-sm-font">{l.start}</div>
                  <div className="ct-col ct-sm-font">{l.end}</div>
                </div>
              ))}
            </div>
          </>
        )}

        <p style={{ textAlign: 'center' }}>Crop times to crop from video start (in sec)</p>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-sm-font">Start time</div>
            <div className="ct-col ct-sm-font">End time</div>
            <div className="ct-col ct-sm-font ct-qf" />
          </div>
          {times_list.map((l, index) => (
            <div className="ct-row" key={index}>
              <div className="ct-col ct-sm-font">{l.start_time}</div>
              <div className="ct-col ct-sm-font">{l.end_time}</div>
              <div className="ct-col ct-sm-font ct-qf ct-si">
                {!!start_time && !!end_time ? (
                  <img
                    src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                    alt="nav"
                    onClick={() => this.delete_row(index)}
                  />
                ) : null}
              </div>
            </div>
          ))}
          <div className="ct-row">
            <div className="ct-col ct-sm-font">{start_time === -1 ? null : start_time}</div>
            <div className="ct-col ct-sm-font">{end_time === -1 ? null : end_time}</div>
            <div className="ct-col ct-sm-font ct-qf ct-si">
              {start_time !== -1 ? (
                <img
                  src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                  alt="nav"
                  onClick={this.remove_dates}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  submit_crop = () => {
    this.setState({ loading: true }, () => {
      const { start_time, end_time, times_list, session_uuid } = this.state;
      const final_times_list = [...times_list];
      if (start_time !== -1 && end_time !== -1) {
        final_times_list.push({ start_time, end_time });
      }
      const payload = {
        session_uuid,
        crop_timing: final_times_list
      };
      const url = get_api_url('add_recording_crop_timing');
      post_api(url, payload, true)
        .then((res) => {
          this.load_data();
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  render_submit = () => {
    const { start_time, end_time, times_list } = this.state;
    let time_flag = false;
    let time_other_flag = false;
    time_flag = start_time !== -1 && end_time !== -1;
    time_other_flag = start_time === -1 && end_time === -1;
    if (
      (times_list.length > 0 && (time_flag || time_other_flag)) ||
      (times_list.length === 0 && time_flag)
    )
      return <button onClick={this.submit_crop}>Submit</button>;
  };

  render_replay = () => {
    const { start_time, end_time, replay_url } = this.state;
    let btn_text = '';
    if (start_time === -1 || end_time !== -1) {
      btn_text = 'Log start time';
    } else {
      btn_text = 'Log end time';
    }
    return (
      <div className="cr-cont">
        <div className="cr-player">
          <Player
            playsInline
            src={replay_url}
            ref={(player) => {
              this.player = player;
            }}
          >
            <BigPlayButton position="center" />
            <ControlBar>
              <PlaybackRateMenuButton rates={[15, 10, 5, 2, 1]} />
              <ForwardControl clasName="cvr-back-10" seconds={-30} order={3.2} />
              <ForwardControl clasName="cvr-back-10" seconds={-10} order={3.2} />
              <ForwardControl clasName="cvr-back-10" seconds={10} order={3.3} />
              <ForwardControl seconds={30} order={3.34} />
            </ControlBar>
          </Player>
          <button onClick={this.log_time}>{btn_text}</button>
          {this.render_submit()}
        </div>
        <div className="cr-logs">{this.render_logs()}</div>
      </div>
    );
  };

  render_function = () => {
    const { replay_url, session_uuid, loaded } = this.state;
    if (!!replay_url && !!session_uuid) {
      if (loaded) {
        return this.render_replay();
      }
    }
    return <div>No replay url present</div>;
  };

  go_back = () => {
    this.props.history.goBack();
  };

  render() {
    const { recording_status } = this.state;
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.go_back}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">
            <div>Edit Recording</div>
            {recording_status ? (
              <div className="common-page-subheading">{`( status: ${recording_status})`}</div>
            ) : null}
          </div>
        </div>
        {this.render_function()}
      </div>
    );
  }
}
