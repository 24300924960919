import React from 'react';

const EMAIL_STATUS_COLORS = {
    "Email Initiated": "#3b82f6", // bg-blue-500
    "Email Sent": "#22c55e",      // bg-green-500
    "Email Error": "#ef4444",     // bg-red-500
    "Email Complaint": "#f97316", // bg-orange-500
    "Email Bounced": "#a855f7",   // bg-purple-500
    "Link Clicked": "#eab308",    // bg-yellow-500
    "Email Opened": "#93c5fd",    // bg-blue-300
    "Email Delivered": "#15803d"  // bg-green-700
};

const StatusCodeLegend = () => {
    return (
        <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="text-md font-bold mb-3 text-center">Tracking Status code</h2>
            <div className="grid grid-cols-2 gap-2">
                {Object.entries(EMAIL_STATUS_COLORS).map(([status, color]) => (
                    <div key={status} className="flex items-center space-x-2">
                        <div
                            className="w-4 h-4 rounded-full"
                            style={{ backgroundColor: color }}
                        />
                        <span className="text-xs text-gray-700">{status}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StatusCodeLegend;