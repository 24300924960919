/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { get_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';

const ckeys = [
  'introduction',
  'main_goals',
  'health_history',
  'fitness_history',
  'yoga_experience',
  'lifestyle',
  'session_practice',
  'contra_indications',
  'future_plan',
  'teacher_observations'
];

// eslint-disable-next-line react/prop-types
function AIGettingToKnowStudentBetter({ data }) {
  const [hasData, setHasData] = useState(false);
  const [notes, setNotes] = useState(() => new Map(ckeys.map((key) => [key, []])));
  const [keys, setKeys] = useState(() => ckeys);

  const updateNotes = async (response) => {
    const items = new Map(Object.entries(response?.data?.data));
    Array.from(items.keys()).forEach((key) => {
      let values = items.get(key) || [];

      values = values.map((item) => ({
        key: uuidv4(),
        val: item.replaceAll('"', '')
      }));

      items.set(key, values);
    });

    setNotes(items);

    const mKeys = Array.from(ckeys);

    Array.from(items.keys()).forEach((akey) => {
      if (!mKeys.includes(akey)) {
        mKeys.push(akey);
      }
    });

    const fKeys = [];

    mKeys.forEach((mKey) => {
      if (items.has(mKey)) {
        fKeys.push(mKey);
      }
    });

    setKeys(fKeys);
    setHasData(true);
  };

  const loadNotes = async (studentUUID) => {
    const url = get_api_url(
      `/v2/crons-tasks/poc/student/${studentUUID}/session_notes?session_count=1`,
      false,
      true
    );

    try {
      const response = await get_api(url, true);
      updateNotes(response);
    } catch (e) {
      console.log('api call failed', e);
    }
  };

  const resyncNotes = async () => {
    // eslint-disable-next-line react/prop-types
    const { student_uuid } = data;

    setHasData(false);

    const url = get_api_url(
      `/v2/crons-tasks/event_resync_student_notes?student_uuid=${student_uuid}`,
      false,
      true
    );

    try {
      const response = await get_api(url, true);
      updateNotes(response);
    } catch {
      setHasData(true);
    }
  };

  useEffect(() => {
    const { student_uuid } = data;
    loadNotes(student_uuid);
  }, [data]);

  // eslint-disable-next-line react/no-array-index-key
  return (
    <div className="">
      <div className="mb-10 ">
        <div className="font-bold text-[30px]">AI Getting to know student better</div>
        <button
          className="bg-green-500 hover:bg-green-600 text-white hover:text-white"
          onClick={resyncNotes}
        >
          Resync notes
        </button>
        {!hasData && <p>Loading...</p>}
        {hasData &&
          keys.map((key) => {
            const items = notes.get(key) || [];

            return (
              <div key={key}>
                <div className="font-bold text-[18px] capitalize mt-8">
                  {key.replaceAll('_', ' ')}
                </div>

                {items.length === 0 && (
                  <p className="text-[14px] capitalize mt-8 italic">
                    Call transcript is missing details to generate this section
                  </p>
                )}
                {items.length > 0 && (
                  <ul className="list-disc list-inside">
                    {items.map((item) => (
                      <li key={`${item.key}`}>{item.val}</li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default AIGettingToKnowStudentBetter;
