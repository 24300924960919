import React, { Component } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { post_api } from '../../../../redux/api_funcs';
import * as actions from '../../../../redux/action_creators';
import 'react-datepicker/dist/react-datepicker.css';
import './availability.css';
import { get_api_url } from '../../../../utils/urls';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import { FirstSecondThirdLineCoach, lineCoach } from '../../../../constants';
import CustomRadioButton from '../../../util_components/custom_radio_button';

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const availDuration = [
  {
    label: 'Both 30 & 60 mins',
    value: 'all'
  },
  {
    label: 'Only 30 mins',
    value: 30
  },
  {
    label: 'Only 60 mins',
    value: 60
  }
];
class AllTeacherAvailability extends Component {
  state = {
    time_array: [...Array(48).keys()],
    all_sum_arr: Array(48).fill(0),
    all_availability_array: [],
    platform_sum_arr: Array(48).fill(0),
    platform_availability_array: [],
    session_sum_arr: Array(48).fill(0),
    session_availability_array: [],
    loading: true,
    start_date: moment().toDate(),
    end_date: moment().add(7, 'd').toDate(),
    redirectLogin: false,
    zero_availability_teachers: [],
    coach_level: lineCoach.all,
    avail_duration: 60
  };

  componentDidMount() {
    this.load_teacher_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.setState({ loading: true }, () => {
        this.load_teacher_data();
      });
    }
  }

  parse_availability_data = (data) => {
    const avail_array = [...data];
    const sum_for_each = Array(48).fill(0);
    const teachers_list = [];
    const { length } = avail_array;
    for (let i = 0; i < length; i++) {
      avail_array[i].time_slots.forEach((list) => {
        list.teachers.forEach((teacher) => {
          const index = teachers_list.findIndex((x) => x.uuid === teacher.uuid);
          if (index === -1) {
            teachers_list.push({ uuid: teacher.uuid, name: teacher.name, count: 1 });
          } else {
            teachers_list[index] = {
              ...teachers_list[index],
              count: teachers_list[index].count + 1
            };
          }
        });
      });
      let arr = avail_array[i].time_slots.map((item) => item.available);
      if (i === 0) {
        arr = Array(48 - arr.length)
          .fill(0)
          .concat(arr);
      }
      if (i === length - 1) {
        arr = arr.concat(Array(48 - arr.length).fill(0));
      }
      for (let j = 0; j < sum_for_each.length; ++j) sum_for_each[j] = sum_for_each[j] + arr[j];
    }
    return [[...sum_for_each], [...teachers_list]];
  };

  load_teacher_data = () => {
    const url = get_api_url('teacher_availability');
    const zero_url = get_api_url('get_zero_availability_teachers');
    const start_date = `${moment(this.state.start_date).format('YYYY-MM-DD')} 00:00:00`;
    const end_date = `${moment(this.state.end_date).format('YYYY-MM-DD')} 23:30:00`;
    const data = {
      start_date,
      end_date,
      iana_timezone: this.props.iana_timezone
    };
    const all_payload = {
      start_date: moment(this.state.start_date).format('YYYY-MM-DD'),
      end_date: moment(this.state.end_date).add(1, 'days').format('YYYY-MM-DD')
    };
    this.props.avail_all_in_one(all_payload);
    this.setState({ loading: true });
    try {
      post_api(url, data, true).then((response) => {
        const filteredTeachers = response.data.availability.map((item) => ({
          ...item,
          time_slots: item.time_slots.map((slot) => {
            const filteredTeachers = slot.teachers.filter((teacher) => {
              const { coach_level } = this.state;
              return (
                coach_level === lineCoach.all ||
                (coach_level === lineCoach.first_line &&
                  teacher.coach_level === lineCoach.first_line) ||
                (coach_level === lineCoach.second_line &&
                  teacher.coach_level === lineCoach.second_line) ||
                (coach_level === lineCoach.third_line &&
                  teacher.coach_level === lineCoach.third_line)
              );
            });
            return {
              ...slot,
              teachers: filteredTeachers,
              available: filteredTeachers.length
            };
          })
        }));

        const [all_sum_arr, all_sum_count] = this.parse_availability_data(filteredTeachers);

        post_api(url, { ...data, available_slots: 1 }, true).then((response1) => {
          const filteredPlatformTeachers = response1.data.availability.map((item) => ({
            ...item,
            time_slots: item.time_slots.map((slot) => {
              const filteredTeachers = slot.teachers.filter((teacher) => {
                const { coach_level } = this.state;
                return (
                  coach_level === lineCoach.all ||
                  (coach_level === lineCoach.first_line &&
                    teacher.coach_level === lineCoach.first_line) ||
                  (coach_level === lineCoach.second_line &&
                    teacher.coach_level === lineCoach.second_line) ||
                  (coach_level === lineCoach.third_line &&
                    teacher.coach_level === lineCoach.third_line)
                );
              });
              return {
                ...slot,
                teachers: filteredTeachers,
                available: filteredTeachers.length
              };
            })
          }));

          const [platform_sum_arr, platform_sum_count] =
            this.parse_availability_data(filteredPlatformTeachers);
          post_api(url, { ...data, available_slots: 0 }, true).then((response2) => {
            const filteredSessionTeachers = response2.data.availability.map((item) => ({
              ...item,
              time_slots: item.time_slots.map((slot) => {
                const filteredTeachers = slot.teachers.filter((teacher) => {
                  const { coach_level } = this.state;
                  return (
                    coach_level === lineCoach.all ||
                    (coach_level === lineCoach.first_line &&
                      teacher.coach_level === lineCoach.first_line) ||
                    (coach_level === lineCoach.second_line &&
                      teacher.coach_level === lineCoach.second_line) ||
                    (coach_level === lineCoach.third_line &&
                      teacher.coach_level === lineCoach.third_line)
                  );
                });
                return {
                  ...slot,
                  teachers: filteredTeachers,
                  available: filteredTeachers.length
                };
              })
            }));

            const [session_sum_arr, session_sum_count] =
              this.parse_availability_data(filteredSessionTeachers);
            this.setState({
              session_availability_array: filteredSessionTeachers,
              session_sum_arr,
              session_sum_count,
              platform_availability_array: filteredPlatformTeachers,
              platform_sum_arr,
              platform_sum_count,
              all_availability_array: filteredTeachers,
              all_sum_arr,
              all_sum_count,
              loading: false
            });
            post_api(zero_url, all_payload, true).then((zero_response) => {
              this.setState({ zero_availability_teachers: [...zero_response.data.teachers] });
            });
          });
        });
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        this.setState({ redirectLogin: true });
      }
    }
  };

  render_table_header = () => (
    <tr>
      <th className="all-table-header">Day</th>
      <th className="all-table-header">Date</th>
      {this.state.time_array.map((item, index) => (
        <th className="all-table-header" key={item + index}>
          {item % 2 === 0 ? item / 2 : ''}
        </th>
      ))}
      <th className="all-table-header">Total</th>
    </tr>
  );

  render_blank_td = (availabality) => {
    const temp_length = 48 - availabality.time_slots.length;
    const temp_array = [...Array(temp_length).keys()];
    return (
      <>
        {temp_array.map((item, index) => (
          <td style={{ border: 'none' }} key={item + index} />
        ))}
      </>
    );
  };

  render_table_rows = () => {
    const availability_array = this.state.all_availability_array;
    return (
      <>
        {availability_array.map((availabality, parent_index) => {
          const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
          let total = 0;
          return (
            <tr key={parent_index + availabality.date}>
              <td className="all-table-value day-format">
                {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
              </td>
              <td className="all-table-value day-format">{availabality.date}</td>
              {parent_index === 0 ? this.render_blank_td(availabality) : null}
              {availabality.time_slots.map((slot, index) => {
                total += slot.available;
                const color = max_value === 0 ? 0 : slot.available / max_value;
                const td_style =
                  slot.available === 0 || max_value === 0
                    ? { background: 'rgb(255,255,255)' }
                    : { background: `rgba(0,0,255,${color})` };
                return (
                  <td style={td_style} key={index} className="all-table-value hover-parent">
                    <div className="tooltiptext">
                      No of teachers {slot.available}
                      {slot.teachers.map((teacher, index) => (
                        <span key={teacher.uuid}>
                          <br />
                          {teacher.name}
                        </span>
                      ))}
                    </div>
                    {slot.available}
                  </td>
                );
              })}
              {parent_index === availability_array.length - 1
                ? this.render_blank_td(availabality)
                : null}
              <td className="all-table-value total-table-column">
                <span className="color-blue">{total}</span>
              </td>
            </tr>
          );
        })}
        <tr>
          <td />
          <td className="all-table-value total-table-column total-heading">Total</td>
          {this.state.all_sum_arr.map((val, index) => (
            <td className="all-table-value total-table-column" key={index}>
              <span className="color-blue">{val}</span>
            </td>
          ))}
          <td className="all-table-value total-table-column">
            <span className="color-blue">{this.state.all_sum_arr.reduce((a, b) => a + b)}</span>
          </td>
        </tr>
      </>
    );
  };

  areConsecutiveSlots = (slot1, slot2) => {
    const time1 = moment(slot1.time);
    const time2 = moment(slot2.time);
    return time2.diff(time1, 'minutes') === 30;
  };

  // Helper function to get teachers available for 60 minutes
  get60MinTeachers = (timeSlots, currentIndex) => {
    if (currentIndex + 1 >= timeSlots.length) return [];

    const currentSlot = timeSlots[currentIndex];
    const nextSlot = timeSlots[currentIndex + 1];

    if (!this.areConsecutiveSlots(currentSlot, nextSlot)) return [];

    return currentSlot.teachers.filter((currentTeacher) =>
      nextSlot.teachers.some((nextTeacher) => nextTeacher.uuid === currentTeacher.uuid)
    );
  };

  get30MinOnlyTeachers = (timeSlots, currentIndex) => {
    if (currentIndex >= timeSlots.length) return [];

    const currentSlot = timeSlots[currentIndex];
    const nextSlot = timeSlots[currentIndex + 1];
    console.log('nextSlot: ', nextSlot);

    if (!nextSlot) return currentSlot.teachers;

    // Return teachers who are only in current slot
    return currentSlot.teachers.filter(
      (currentTeacher) =>
        !nextSlot.teachers.some((nextTeacher) => nextTeacher.uuid === currentTeacher.uuid)
    );
  };

  getAvailableTeachers = (timeSlots, currentSlotIndex) => {
    const durationType = this.state.avail_duration;
    if (!timeSlots || currentSlotIndex >= timeSlots.length) {
      return [];
    }

    switch (durationType) {
      case 60: {
        // For 60 min availability, we need to check 2 consecutive 30-min slots
        return this.get60MinTeachers(timeSlots, currentSlotIndex);
      }

      case 30:
        // For 30 min availability, return teachers in current slot
        return this.get30MinOnlyTeachers(timeSlots, currentSlotIndex);
      case 'all':
      default:
        const sixtyMinTeachers = this.get60MinTeachers(timeSlots, currentSlotIndex);
        const thirtyMinOnlyTeachers = this.get30MinOnlyTeachers(timeSlots, currentSlotIndex);

        return [
          ...new Map([
            ...sixtyMinTeachers.map((t) => [t.uuid, { ...t, duration: '60min' }]),
            ...thirtyMinOnlyTeachers.map((t) => [t.uuid, { ...t, duration: '30min' }])
          ]).values()
        ];
    }
  };

  render_platform_table_rows = () => {
    const availability_array = this.state.platform_availability_array;

    return (
      <>
        {availability_array.map((availabality, parent_index) => {
          const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
          let total = 0;
          return (
            <tr key={parent_index + availabality.date}>
              <td className="all-table-value day-format">
                {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
              </td>
              <td className="all-table-value day-format">{availabality.date}</td>
              {parent_index === 0 ? this.render_blank_td(availabality) : null}
              {availabality.time_slots.map((slot, index) => {
                const availableTeachers = this.getAvailableTeachers(availabality.time_slots, index);
                total += availableTeachers.length;
                const color = max_value === 0 ? 0 : slot.available / max_value;
                const td_style =
                  slot.available === 0 || max_value === 0
                    ? { background: 'rgb(255,255,255)' }
                    : { background: `rgba(0,0,255,${color})` };
                return (
                  <td style={td_style} key={index} className="all-table-value hover-parent">
                    <div className="tooltiptext">
                      No of teachers {availableTeachers.length}
                      {availableTeachers.map((teacher, index) => (
                        <span key={teacher.uuid}>
                          <br />
                          {teacher.name}
                        </span>
                      ))}
                    </div>
                    {availableTeachers.length}
                  </td>
                );
              })}
              {parent_index === availability_array.length - 1
                ? this.render_blank_td(availabality)
                : null}
              <td className="all-table-value total-table-column">
                <span className="color-blue">{total}</span>
              </td>
            </tr>
          );
        })}
        <tr>
          <td />
          <td className="all-table-value total-table-column total-heading">Total</td>
          {this.state.platform_sum_arr.map((val, index) => (
            <td className="all-table-value total-table-column" key={index}>
              <span className="color-blue">{val}</span>
            </td>
          ))}
          <td className="all-table-value total-table-column">
            <span className="color-blue">
              {this.state.platform_sum_arr.reduce((a, b) => a + b)}
            </span>
          </td>
        </tr>
      </>
    );
  };

  render_session_table_rows = () => {
    const availability_array = this.state.session_availability_array;
    return (
      <>
        {availability_array.map((availabality, parent_index) => {
          const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
          let total = 0;
          return (
            <tr key={parent_index + availabality.date}>
              <td className="all-table-value day-format">
                {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
              </td>
              <td className="all-table-value day-format">{availabality.date}</td>
              {parent_index === 0 ? this.render_blank_td(availabality) : null}
              {availabality.time_slots.map((slot, index) => {
                total += slot.available;
                const color = max_value === 0 ? 0 : slot.available / max_value;
                const td_style =
                  slot.available === 0 || max_value === 0
                    ? { background: 'rgb(255,255,255)' }
                    : { background: `rgba(0,0,255,${color})` };
                return (
                  <td style={td_style} key={index} className="all-table-value hover-parent">
                    <div className="tooltiptext">
                      No of teachers {slot.available}
                      {slot.teachers.map((teacher, index) => (
                        <span key={teacher.uuid}>
                          <br />
                          {teacher.name}
                        </span>
                      ))}
                    </div>
                    {slot.available}
                  </td>
                );
              })}
              {parent_index === availability_array.length - 1
                ? this.render_blank_td(availabality)
                : null}
              <td className="all-table-value total-table-column">
                <span className="color-blue">{total / 2}</span>
              </td>
            </tr>
          );
        })}
        <tr>
          <td />
          <td className="all-table-value total-table-column total-heading">Total</td>
          {this.state.session_sum_arr.map((val, index) => (
            <td className="all-table-value total-table-column" key={index}>
              <span className="color-blue">{val / 2}</span>
            </td>
          ))}
          <td className="all-table-value total-table-column">
            <span className="color-blue">
              {this.state.session_sum_arr.reduce((a, b) => a + b) / 2}
            </span>
          </td>
        </tr>
      </>
    );
  };

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  handleCoachLevel = (e) => {
    const coachLevel = e.target.value;
    this.setState({ coach_level: coachLevel });
  };

  on_submit = () => {
    if (this.state.uuid !== '') {
      this.load_teacher_data();
    }
  };

  toggle_avail_duration = (value) => {
    this.setState({
      avail_duration: value
    });
  };

  render_data = () => {
    if (this.state.loading) {
      return <div style={{ padding: '20px' }}>Loading...</div>;
    }
    if (this.state.all_availability_array.length === 0) {
      return (
        <div className="teacher-avilability">
          <h4>Teachers Availability</h4>
          <p>NO DATA AVAILABLE</p>
        </div>
      );
    }
    return (
      <>
        <div className="availability-table availability-table-style">
          <h4>Current availability on platform:</h4>
          <div className="flex justify-center items-center mt-4 mb-4 space-x-4">
            <span className="text-sm">Select availability duration:</span>
            {availDuration.map((option, index) => (
              <CustomRadioButton
                key={index}
                label={option.label}
                onRadioClick={() => {
                  this.toggle_avail_duration(option.value);
                }}
                selected={this.state.avail_duration === option.value}
              />
            ))}
          </div>
          <table>
            <tbody>
              {this.render_table_header()}
              {this.render_platform_table_rows()}
            </tbody>
          </table>
        </div>
        <div className="availability-table availability-table-style">
          <h4>Sessions booked:</h4>
          <table>
            <tbody>
              {this.render_table_header()}
              {this.render_session_table_rows()}
            </tbody>
          </table>
        </div>
        <div className="availability-table availability-table-style">
          <h4>Availability given by teachers:</h4>
          <table>
            <tbody>
              {this.render_table_header()}
              {this.render_table_rows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  render_filters = () => (
    <div>
      <hr />
      <h4>Date range:</h4>
      <div className="teacher-availability-filters">
        <div className="teacher-availability-block">
          <div>Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            maxDate={this.state.end_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <div>End Date:</div>
          <DatePicker
            selected={this.state.end_date}
            minDate={this.state.start_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'end_date')}
          />
        </div>
        <div>
          <CustomSingleSelect
            label="Select a Teacher"
            options={FirstSecondThirdLineCoach}
            value={this.state.coach_level}
            onChange={this.handleCoachLevel}
          />
        </div>
        <div className="teacher-availability-block">
          <button style={{ marginTop: '25px' }} onClick={this.on_submit}>
            Submit
          </button>
        </div>
      </div>
      <hr />
    </div>
  );

  render_all_data = () => {
    const { avail_all } = this.props;
    return (
      <>
        <h4>Overall availability:</h4>
        <table>
          <tbody>
            <tr>
              <td rowSpan="2">Date</td>
              <td colSpan="3">Availability</td>
              <td colSpan="3">Booking</td>
              <td colSpan="3">Percentage</td>
            </tr>
            <tr>
              <td>CST</td>
              <td>PST</td>
              <td>EST</td>
              <td>CST</td>
              <td>PST</td>
              <td>EST</td>
              <td>CST</td>
              <td>PST</td>
              <td>EST</td>
            </tr>
            {avail_all.map((av, index) => (
              <tr>
                <td>{av.date}</td>
                <td>{parseFloat(av.cst_av).toFixed(1)}</td>
                <td>{parseFloat(av.pst_av).toFixed(1)}</td>
                <td>{parseFloat(av.est_av).toFixed(1)}</td>
                <td>{av.cst_bk}</td>
                <td>{av.pst_bk}</td>
                <td>{av.est_bk}</td>
                <td>{parseFloat(av.cst_per).toFixed(2)}</td>
                <td>{parseFloat(av.pst_per).toFixed(2)}</td>
                <td>{parseFloat(av.est_per).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  render_zero_avail_data = () => {
    const teachers = this.state.zero_availability_teachers;
    teachers.sort((a, b) => a.name.localeCompare(b.name));
    if (teachers.length > 0) {
      return (
        <>
          <h4>Teachers with zero availability:</h4>
          <table>
            <tbody>
              <tr>
                <td>Teacher</td>
                <td>Status</td>
              </tr>
              {teachers.map((teacher) => {
                const teacher_url = `/teacherprofile?id=${teacher.uuid}`;
                return (
                  <tr key={teacher.uuid}>
                    <td>
                      <a className="link-no-dec" href={teacher_url}>
                        {teacher.name}
                      </a>
                    </td>
                    <td>{teacher.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  render_table_data = () => {
    const booked_sessions = this.state.session_sum_count;
    const available_hours = this.state.platform_sum_count;
    const total_hours = this.state.all_sum_count;
    total_hours.sort((a, b) => b.count - a.count);
    return (
      <>
        <br />
        {this.render_zero_avail_data()}
        <h4>Teacher specific:</h4>
        <table>
          <tbody>
            <tr>
              <td>Teacher</td>
              <td>Total Hours</td>
              <td>Booked Hours</td>
              <td>Available Hours</td>
            </tr>
            {total_hours.map((teacher) => {
              const booked_sessions_index = booked_sessions.findIndex(
                (x) => x.uuid === teacher.uuid
              );
              const available_hours_index = available_hours.findIndex(
                (x) => x.uuid === teacher.uuid
              );
              const teacher_url = `/teacherprofile?id=${teacher.uuid}`;
              return (
                <tr key={teacher.uuid}>
                  <td>
                    <a className="link-no-dec" href={teacher_url}>
                      {teacher.name}
                    </a>
                  </td>
                  <td>{teacher.count / 2}</td>
                  <td>
                    {(booked_sessions[booked_sessions_index]
                      ? booked_sessions[booked_sessions_index].count
                      : 0) / 2}
                  </td>
                  <td>
                    {(available_hours[available_hours_index]
                      ? available_hours[available_hours_index].count
                      : 0) / 2}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  render() {
    const url = new URL(window.location.href);
    const redirect = `/login?redirect_url=${url.pathname}${url.search}`;
    if (this.state.redirectLogin) {
      return <Redirect to={redirect} />;
    }
    return (
      <div className="common-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>All Teachers Availability</title>
        </Helmet>
        <h2>All Teachers Availability</h2>
        {this.render_filters()}
        {this.render_data()}
        {!this.state.loading && this.props.avail_all_status === 'success'
          ? this.render_all_data()
          : null}
        {this.state.loading ? null : this.render_table_data()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  avail_all_status: state.loading.avail_all_status,
  avail_all: state.reports.avail_all
});

const mapDispatchToProps = (dispatch) => ({
  avail_all_in_one: (payload) => dispatch(actions.avail_all_in_one(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTeacherAvailability);
