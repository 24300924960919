export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const weekday_values = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' }
];

export const custom_data_configs = [
  { value: 'number', label: 'Integer' },
  { value: 'string', label: 'String' }
];

export const new_custom_data_config = [
  { value: 'number', label: 'Number' },
  { value: 'string', label: 'String' }
];

export const date_drop_for_report = [
  { value: 'TODAY', label: 'TODAY' },
  { value: 'YESTERDAY', label: 'YESTERDAY' },
  { value: 'THIS WEEK', label: 'THIS WEEK' },
  { value: 'LAST WEEK', label: 'LAST WEEK' },
  { value: 'LAST 2 WEEKS', label: 'LAST 2 WEEKS' },
  { value: 'LAST 4 WEEKS', label: 'LAST 4 WEEKS' },
  { value: 'THIS MONTH', label: 'THIS MONTH' },
  { value: 'LAST MONTH', label: 'LAST MONTH' },
  { value: 'LAST 30 DAYS', label: 'LAST 30 DAYS' },
  { value: 'CUSTOM', label: 'CUSTOM' }
];

export const trial_end_date_drop_for_report = [
  { value: 'TODAY', label: 'TODAY' },
  { value: 'TOMORROW', label: 'TOMORROW' },
  { value: 'THIS WEEK', label: 'THIS WEEK' },
  { value: 'NEXT WEEK', label: 'NEXT WEEK' },
  { value: 'CUSTOM', label: 'CUSTOM' }
];

export const onboarding_status = [
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'UNDER_EVALUATION', label: 'UNDER EVALUATION' },
  { value: 'UNDER_EVALUATION_ON_HOLD', label: 'UNDER EVALUATION ON HOLD' },
  { value: 'INTERESTED_TO_JOIN', label: 'INTERESTED TO JOIN' },
  { value: 'VIDEO_CONSULT', label: 'VIDEO CONSULT' },
  { value: 'UNDER_PROBATION', label: 'UNDER PROBATION' },
  { value: 'REJECTED', label: 'REJECTED' },
  { value: 'TEMPORARILY_UNAVAILABLE', label: 'TEMPORARILY UNAVAILABLE' },
  { value: 'UNDER_SCRUTINY', label: 'UNDER SCRUTINY' },
  { value: 'RESIGNED', label: 'RESIGNED' },
  { value: 'SUSPENDED', label: 'SUSPENDED' }
];
export const iana_values = [
  { value: 'America/Los_Angeles', label: 'PST' },
  { value: 'America/New_York', label: 'EST' },
  { value: 'America/Chicago', label: 'CST' },
  { value: 'Asia/Kolkata', label: 'IST' },
  { value: 'America/Denver', label: 'MST' },
  { value: 'Europe/London', label: 'GMT' }
];

export const ianaTimezones = [
  {
    label: 'USA',
    options: [
      { value: 'America/Los_Angeles', label: 'Pacific (PST)' },
      { value: 'America/Denver', label: 'Mountain (MST)' },
      { value: 'America/Chicago', label: 'Central (CST)' },
      { value: 'America/New_York', label: 'Eastern (EST)' }
    ]
  },
  {
    label: 'India',
    options: [{ value: 'Asia/Kolkata', label: 'IST' }]
  },
  {
    label: 'London',
    options: [{ value: 'Europe/London', label: 'GMT' }]
  },
  {
    label: 'Australia',
    options: [
      { value: 'Australia/Eucla', label: 'Eucla(+08:45)' },
      { value: 'Australia/Perth', label: 'Perth (AWST)' },
      { value: 'Australia/Adelaide', label: 'Adelaide (ACDT)' },
      { value: 'Australia/Darwin', label: 'Darwin (ACST)' },
      { value: 'Australia/ACT', label: 'Canberra (AEDT)' },
      { value: 'Australia/Brisbane', label: 'Brisbane (AEST' },
      { value: 'Australia/LHI', label: 'Lord_Howe (+10:30 | +11)' }
    ]
  },
  {
    label: 'Canada',
    options: [
      { value: 'America/Vancouver', label: 'Pacific (PST)' },
      { value: 'America/Edmonton', label: 'Mountain (MST)' },
      { value: 'America/Winnipeg', label: 'Central (CST)' },
      { value: 'America/Toronto', label: 'Eastern (EST)' }
    ]
  },
  {
    label: 'Europe',
    options: [
      { value: 'Europe/Vienna', label: 'Austria (CET)' },
      { value: 'Europe/Brussels', label: 'Belgium (CET)' },
      { value: 'Europe/Zagreb', label: 'Croatia (CET)' },
      { value: 'Europe/Nicosia', label: 'Cyprus (EET)' },
      { value: 'Europe/Tallinn', label: 'Estonia (EET)' },
      { value: 'Europe/Helsinki', label: 'Finland (EET)' },
      { value: 'Europe/Paris', label: 'France (CET)' },
      { value: 'Europe/Berlin', label: 'Germany (CET)' },
      { value: 'Europe/Athens', label: 'Greece (EET)' },
      { value: 'Europe/Dublin', label: 'Ireland (GMT)' },
      { value: 'Europe/Rome', label: 'Italy (CET)' },
      { value: 'Europe/Riga', label: 'Latvia (EET)' },
      { value: 'Europe/Vilnius', label: 'Lithuania (EET)' },
      { value: 'Europe/Luxembourg', label: 'Luxembourg (CET)' },
      { value: 'Europe/Malta', label: 'Malta (CET)' },
      { value: 'Europe/Amsterdam', label: 'Netherlands (CET)' },
      { value: 'Europe/Lisbon', label: 'Portugal (WET)' },
      { value: 'Europe/Bratislava', label: 'Slovakia (CET)' },
      { value: 'Europe/Ljubljana', label: 'Slovenia (CET)' },
      { value: 'Europe/Madrid', label: 'Spain (CET)' }
    ]
  },
  {
    label: 'Middle East',
    options: [
      { value: 'Asia/Dubai', label: 'United Arab Emirates (GST)' },
      { value: 'Asia/Riyadh', label: 'Saudi Arabia (AST)' },
      { value: 'Asia/Kuwait', label: 'Kuwait (AST)' }
    ]
  },
  {
    label: 'Asia',
    options: [
      { value: 'Asia/Singapore', label: 'Singapore (SGT)' },
      { value: 'Asia/Kuala_Lumpur', label: 'Malaysia (MYT)' }
    ]
  },
  {
    label: 'Africa',
    options: [{ value: 'Africa/Johannesburg', label: 'South Africa (SAST)' }]
  },
  {
    label: 'New Zealand',
    options: [{ value: 'Pacific/Auckland', label: 'NZST' }]
  }
];

export const new_yoga_levels = [
  { value: 'Newbie', label: 'Newbie' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Advanced', label: 'Advanced' }
];

export const back_pain_intensity = [
  { value: '0 : no pain', label: '0 : no pain' },
  { value: '1 : slight pain', label: '1 : slight pain' },
  { value: '2 : mild pain', label: '2 : mild pain' },
  { value: '3 : low moderate pain', label: '3 : low moderate pain' },
  { value: '4 : moderate pain', label: '4 : moderate pain' },
  {
    value: '5 : pain that intrudes on your concentration',
    label: '5 : pain that intrudes on your concentration'
  },
  { value: '6 : somewhat severe pain', label: '6 : somewhat severe pain' },
  { value: '7 : severe pain', label: '7 : severe pain' },
  { value: '8 : very severe pain', label: '8 : very severe pain' },
  { value: '9 : almost intolerable pain', label: '9 : almost intolerable pain' },
  { value: '10 : intolerable', label: '10 : intolerable' }
];

export const client_height_in_ft = [
  { value: 1, label: '1ft' },
  { value: 2, label: '2ft' },
  { value: 3, label: '3ft' },
  { value: 4, label: '4ft' },
  { value: 5, label: '5ft' },
  { value: 6, label: '6ft' },
  { value: 7, label: '7ft' }
];

export const client_height_in_inc = [
  { value: 0, label: '0in' },
  { value: 1, label: '1in' },
  { value: 2, label: '2in' },
  { value: 3, label: '3in' },
  { value: 4, label: '4in' },
  { value: 5, label: '5in' },
  { value: 6, label: '6in' },
  { value: 7, label: '7in' },
  { value: 8, label: '8in' },
  { value: 9, label: '9in' },
  { value: 10, label: '10in' },
  { value: 11, label: '11in' }
];

export const new_yoga_types = [
  'Gentle',
  'Vinyasa',
  'Power yoga',
  'Hatha/ hatha flow',
  'Ashtanga',
  'Yin',
  'Restorative',
  'Iyengar',
  'Yoga fusion',
  'Kundalini',
  'Intermediate',
  'Meditation'
];

export const years_of_exp = [
  { value: 'Less than 2 years', label: 'Less than 2 years' },
  { value: '2 - 5 years', label: '2 - 5 years' },
  { value: '5 - 10 years', label: '5 - 10 years' },
  { value: 'More than 10 years', label: 'More than 10 years' }
];

export const genders = [
  { value: 'MALE', label: 'MALE' },
  { value: 'FEMALE', label: 'FEMALE' }
];

export const marital_status = [
  { value: 'MARRIED', label: 'MARRIED' },
  { value: 'UNMARRIED', label: 'UNMARRIED' }
];

export const yoga_teaching_list = [
  'Gentle',
  'Vinyasa',
  'Power yoga',
  'Hatha/ hatha flow',
  'Bikram/ hot',
  'Ashtanga',
  'Yin',
  'Restorative',
  'Iyengar',
  'Yoga fusion',
  'Kundalini'
];

export const yoga_levels = ['Beginner', 'Intermediate', 'Advanced'];

export const teacher_goals = [
  'Reduce Stress & Anxiety',
  'Improve Strength & Flexibility',
  'Manage Pain',
  'Mindfulness & Inner Calm',
  'Reduce Weight',
  'Get into better shape'
  // 'Some other - specify'
];

export const student_timeslots = [
  '04-07',
  '07-12',
  '12-17',
  '17-22'
  // 'Some other - specify'
];

export const mapped_student_timeslots = {
  '04-07': 'Early morning (4am - 7am)',
  '07-12': 'Morning (7am -12pm)',
  '12-17': 'Afternoon (12pm - 5pm)',
  '17-22': 'Evening (5pm - 10pm)'
  // 'Some other - specify"
};

export const calendar_months_short = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const time_half_values = {
  '00:00': 0,
  '00:30': 0,
  '01:00': 0,
  '01:30': 0,
  '02:00': 0,
  '02:30': 0,
  '03:00': 0,
  '03:30': 0,
  '04:00': 0,
  '04:30': 0,
  '05:00': 0,
  '05:30': 0,
  '06:00': 0,
  '06:30': 0,
  '07:00': 0,
  '07:30': 0,
  '08:00': 0,
  '08:30': 0,
  '09:00': 0,
  '09:30': 0,
  '10:00': 0,
  '10:30': 0,
  '11:00': 0,
  '11:30': 0,
  '12:00': 0,
  '12:30': 0,
  '13:00': 0,
  '13:30': 0,
  '14:00': 0,
  '14:30': 0,
  '15:00': 0,
  '15:30': 0,
  '16:00': 0,
  '16:30': 0,
  '17:00': 0,
  '17:30': 0,
  '18:00': 0,
  '18:30': 0,
  '19:00': 0,
  '19:30': 0,
  '20:00': 0,
  '20:30': 0,
  '21:00': 0,
  '21:30': 0,
  '22:00': 0,
  '22:30': 0,
  '23:00': 0,
  '23:30': 0
};

export const help = {
  first_name: 'MyYogaTeacher',
  help_uuid: '',
  last_name: 'Care',
  middle_name: '',
  profile_photo:
    'https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1556284230/Logo.png',
  twilio_identity: 'myyogateacher-help@myyogateacher_twilio.com'
};

export const help1 = {
  first_name: 'MyYogaTeacher',
  help_uuid: '',
  last_name: 'Care',
  middle_name: '',
  profile_photo:
    'https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1556284230/Logo.png',
  twilio_identity: 'myyogateacher-help1@myyogateacher_twilio.com'
};

export const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],
    ['clean']
  ]
};

export const formats = [
  'font',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];

export const email_template_tags = [
  { label: '[TEACHER-NAME]', value: '[TEACHER-NAME]' },
  { label: '[SESSION-DATE]', value: '[SESSION-DATE]' },
  { label: '[SESSION-TIME]', value: '[SESSION-TIME]' },
  { label: '[STUDENT-NAME]', value: '[STUDENT-NAME]' },
  {
    label: '[CONSULTATION-SESSION-DAY-OR-DATE-TIME]',
    value: '[CONSULTATION-SESSION-DAY-OR-DATE-TIME]'
  },
  { label: '[RATING-URL]', value: '[RATING-URL]' },
  { label: '[SESSION-DAY]', value: '[SESSION-DAY]' },
  { label: '[SESSION-RATING-REASON]', value: '[SESSION-RATING-REASON]' },
  { label: '[SESSION-RATING-REVIEW]', value: '[SESSION-RATING-REVIEW]' },
  { label: '[SESSION-RATING]', value: '[SESSION-RATING]' },
  { label: '[USER-NAME]', value: '[USER-NAME]' },
  { label: '[SESSION-TOKEN]', value: '[SESSION-TOKEN]' },
  { label: '[TEACHER-FIRST-NAME]', value: '[TEACHER-FIRST-NAME]' },
  { label: '[TEACHER-IMAGE]', value: '[TEACHER-IMAGE]' },
  { label: '[TEACHER-YOGA-CERTIFICATES]', value: '[TEACHER-YOGA-CERTIFICATES]' },
  { label: '[SESSION-DAY-OR-DATE-TIME]', value: '[SESSION-DAY-OR-DATE-TIME]' },
  { label: '[STUDENT-EMAIL]', value: '[STUDENT-EMAIL]' },
  { label: '[STUDENT-NUMBER]', value: '[STUDENT-NUMBER]' }
];

export const session_status_values = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'SCHEDULED', value: 'SCHEDULED' },
  { label: 'CANCELLED', value: 'CANCELLED' },
  { label: 'FINISHED', value: 'FINISHED' },
  { label: 'TEACHER_NO_SHOW', value: 'TEACHER_NO_SHOW' },
  { label: 'STUDENT_NO_SHOW', value: 'STUDENT_NO_SHOW' },
  { label: 'STUDENT_AND_TEACHER_NO_SHOW', value: 'STUDENT_AND_TEACHER_NO_SHOW' }
];

// { label: 'REQUEST_CANCELLATION_FROM_STUDENT', value: 'REQUEST_CANCELLATION_FROM_STUDENT' },
// { label: 'REQUEST_CANCELLATION_FROM_TEACHER', value: 'REQUEST_CANCELLATION_FROM_TEACHER' },
// { label: 'CANCELLED_BY_TEACHER', value: 'CANCELLED_BY_TEACHER' },
// { label: 'CANCELLED_BY_STUDENT', value: 'CANCELLED_BY_STUDENT' },
// { label: 'REQUEST_RESCHEDULE_FROM_STUDENT', value: 'REQUEST_RESCHEDULE_FROM_STUDENT' },
// { label: 'REQUEST_RESCHEDULE_FROM_TEACHER', value: 'REQUEST_RESCHEDULE_FROM_TEACHER' },
// { label: 'RESCHEDULED_BY_TEACHER', value: 'RESCHEDULED_BY_TEACHER' },
// { label: 'RESCHEDULED_BY_STUDENT', value: 'RESCHEDULED_BY_STUDENT' },

export const session_duration_list = [
  {
    value: '30',
    label: '30 min'
  },
  {
    value: '45',
    label: '45 min'
  },
  {
    value: '60',
    label: '60 min'
  },
  {
    value: '90',
    label: '90 min'
  },
  {
    value: '120',
    label: '120 min'
  }
];

export const email_audience_types = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'joined',
    label: 'Joined'
  },
  {
    value: 'didnt_join',
    label: 'Didn’t join'
  },
  {
    value: 'cancelled',
    label: 'Cancelled'
  },
  {
    value: 'members',
    label: 'Members'
  },
  {
    value: 'non_members',
    label: 'Non-members'
  }
];

export const long_session_duration_list = [
  {
    value: '30',
    label: '30 min'
  },
  {
    value: '45',
    label: '45 min'
  },
  {
    value: '60',
    label: '60 min'
  },
  {
    value: '90',
    label: '90 min'
  },
  {
    value: '120',
    label: '120 min'
  },
  {
    value: '180',
    label: '3 hr'
  },
  {
    value: '240',
    label: '4 hr'
  },
  {
    value: '300',
    label: '5 hr'
  },
  {
    value: '360',
    label: '6 hr'
  }
];

export const min_list = [
  {
    value: '0',
    label: '0 min'
  },
  {
    value: '15',
    label: '15 min'
  },
  {
    value: '30',
    label: '30 min'
  },
  {
    value: '45',
    label: '45 min'
  }
];

export const hours_list = [
  {
    value: '-60',
    label: '-1 hr'
  },
  {
    value: '0',
    label: '0 hr'
  },
  {
    value: '60',
    label: '1 hr'
  },
  {
    value: '120',
    label: '2 hr '
  },
  {
    value: '180',
    label: '3 hr'
  },
  {
    value: '240',
    label: '4 hr'
  },
  {
    value: '300',
    label: '5 hr'
  },
  {
    value: '360',
    label: '6 hr'
  },
  {
    value: '420',
    label: '7 hr'
  },
  {
    value: '480',
    label: '8 hr'
  },
  {
    value: '540',
    label: '9 hr'
  },
  {
    value: '600',
    label: '10 hr'
  }
];

export const lead_in_hours = [
  {
    value: '0',
    label: '0 hr'
  },
  {
    value: '1',
    label: '1 hr'
  },
  {
    value: '2',
    label: '2 hr '
  },
  {
    value: '3',
    label: '3 hr'
  },
  {
    value: '4',
    label: '4 hr'
  },
  {
    value: '5',
    label: '5 hr'
  },
  {
    value: '6',
    label: '6 hr'
  },
  {
    value: '7',
    label: '7 hr'
  },
  {
    value: '8',
    label: '8 hr'
  },
  {
    value: '9',
    label: '9 hr'
  },
  {
    value: '10',
    label: '10 hr'
  },
  {
    value: '11',
    label: '11 hr'
  },
  {
    value: '12',
    label: '12 hr'
  }
];

export const target_audience_names = [
  { value: 'Newbie', label: 'Newbie' },
  { value: 'Beginner', label: 'Beginner' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Advanced', label: 'Advanced' },
  { value: 'Discussion', label: 'Discussion' },
  { value: 'All Levels', label: 'All Levels' }
];

export const group_types = [
  { value: '0', label: 'Free' },
  { value: '1', label: 'Premium' }
];

export const boolean_values = [
  { value: '0', label: 'False' },
  { value: '1', label: 'True' }
];

export const distribution_types = [
  { value: 'USERS', label: 'USERS' },
  { value: 'DAY_OF_THE_MONTH', label: 'DAY OF THE MONTH' }
];

export const day_distribution_types = [
  { value: 'EVEN_DAY', label: 'EVEN DAY' },
  { value: 'ODD_DAY', label: 'ODD DAY' }
];

export const pagination_count = [
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: 300, label: '300' }
];

export const group_session_names = [
  { value: 'Gentle Yoga', label: 'Gentle Yoga' },
  { value: 'Breathing and Meditation', label: 'Breathing and Meditation' },
  { value: 'Breathing exercise', label: 'Breathing exercise' },
  { value: 'other', label: 'Other' },
  { value: 'Kriya, Pranayama and meditation', label: 'Kriya, Pranayama and meditation' },
  { value: 'Vinyasa', label: 'Vinyasa' },
  { value: 'Kids Yoga', label: 'Kids Yoga' },
  { value: 'Yin Yoga', label: 'Yin Yoga' }
];

const yin_desc = `Yin Yoga is a slow-paced style of yoga as exercise, incorporating principles asanas (postures) that are held for longer periods of time. The sequences of postures are meant to stimulate the channels of the subtle body known as nadis in Hatha yoga.
  Yin Yoga poses apply moderate stress to the connective tissues of the body—the tendons, fasciae, and ligaments—with the aim of increasing circulation in the joints and improving flexibility. A more meditative approach to yoga, its goals are awareness of inner silence, and bringing to light a universal, interconnecting quality`;

const kids_desc = `Yoga for children is a form of modern yoga designed for children. It includes poses to increase strength, flexibility, and coordination. Classes are intended to be fun and focusing on more concentration and improving memory.
  This class includes different poses to improve flexibility, strength, coordination and endurance power. Kids yoga also improves will power and confidence in children. It also helps to focus more on goals.`;

const gentle_desc = `Gentle yoga is a slow and relaxing approach to yoga. In these sessions, you will experience poses with minimal to moderate effort or strain at a slower pace. This relaxed pace will allow you the time to find comfort in the poses and breath slowly and deeply. These sessions provide a calm and grounding practice that will help care for the joints, improve your balance, and find clarity of mind.`;

const breath_medid_desc = `Meditation is a practice to calm and train the mind by using techniques such as mindfulness, focusing on the present moment or the breath and many others. Most styles of meditation begin with building a foundation by focusing on the breath and becoming aware of your thoughts. Meditation has many benefits including peace of mind, stress relief and prevention, emotional stability and resilience`;

const kpm_desc = `The purified and strengthened body, through the practice of kriyas, becomes ready for further intense yoga Sahana.
  
  Purification of the body is extremely important as it results the unrestricted flow of energy throughout the body. Kriyas help the body to clear the energy pathways, alleviate various infections, toxins, several ailments and strengthens the immune system. They help to improve the functioning of the organs or body parts. 
  Simple kriyas which can be included in daily practice are considered to boost self-confidence and promote vibrant health.
  
  
  As the breath moves, so does the mind; when one is steadied, the other too becomes steady. - Hatha Yoga Pradipika - 11.2
  
  The breath is the channel between the body and the mind. Its subtlety, yet sensed presence, enables communication back and forth. It is the medium yogis used to tame and master the mischievous mind.
  Pranayama forms the foundation to meditation.
  
  
  With mind, meditate on me as being prana—Kaushitaki Upanishad, 3.2
  
  The withdrawal of oneself from the outer as well as from the inner world to attain an emotional calm is meditation. This is a very desirable experience for one to be in as it establishes the efficiency and quality in any activity that follows. When one is composed and integrated one can act better.`;

const vin_desc = `"Vinyasa" is derived from the Sanskrit term nyasa, which means "to place," and the prefix vi, "in a special way"—as in the arrangement of notes in a raga, the steps along a path to the top of a mountain, or the linking of one asana to the next.
  
   It can also be a movement between poses in yoga, typically accompanied by regulated breathing or 
  a method of yoga in which vinyasa movements form a flowing sequence in coordination with the breath.`;

export const group_session_descriptions = [
  { value: 'Kriya, Pranayama and meditation', description: kpm_desc },
  { value: 'Vinyasa', description: vin_desc },
  { value: 'Gentle Yoga', description: gentle_desc },
  { value: 'Breathing and Meditation', description: breath_medid_desc },
  { value: 'Kids Yoga', description: kids_desc },
  { value: 'Yin Yoga', description: yin_desc }
];

export const group_session_private_values = [
  { value: '0', label: 'Public' },
  { value: '1', label: 'Private' }
];

export const scholarship_plans_values = [
  {
    value: 'd5bba8c0-eb5f-46bf-8561-480c65d33f62',
    label: 'Unlimited group classes, Zero 1-on-1 sessions '
  },
  {
    value: 'fdb27d51-ca86-4171-a6dd-69cddf7980a6',
    label: 'Unlimited group classes, One 1-on-1 session'
  },
  {
    value: 'd0033044-6cd6-4d18-bd24-6394a90c9018',
    label: 'Unlimited group classes, Two 1-on-1 session'
  },
  { value: '4d7e7bdd-e966-4708-ba84-09e008411b1f', label: 'One 1-on-1 session / week' },
  { value: 'c8a2573e-4717-4afa-ace9-5c9e46fee9d2', label: 'Two 1-on-1 session / week' },
  { value: 'ec68ac55-892e-4a27-bc3f-cbd1d466754a', label: 'Three 1-on-1 sessions / week' },
  { value: '5b780a96-daeb-49fe-b5fb-e8012a52206d', label: 'Four 1-on-1 sessions / week' },
  { value: '8d8c9e80-62ae-4ff9-b343-f7eb6a25dd80', label: 'Five 1-on-1 sessions / week' },
  { value: '9e64b829-dacf-47bb-b37c-ffe57d92f473', label: 'Six 1-on-1 sessions / week' },
  { value: '12156e51-4fbe-41c3-9607-4cd19df3234a', label: 'Seven 1-on-1 sessions / week' }
];

export const scholarship_plans = [
  {
    heading: 'Unlimited group classes',
    sub_heading: 'Zero 1-on-1 sessions',
    pricing: '$29',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 0,
    uuid: 'd5bba8c0-eb5f-46bf-8561-480c65d33f62',
    price: 29,
    logname: 'Member - 0x per weeks',
    package_name: 'Group class',
    best_value: 0,
    sessions_per_week: 0,
    membership_btn: 'Get Membership'
  },
  {
    heading: 'Unlimited group classes',
    sub_heading: 'One 1-on-1 session',
    pricing: '$39',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 0,
    uuid: 'fdb27d51-ca86-4171-a6dd-69cddf7980a6',
    price: 39,
    logname: 'Member - 0.25x per week',
    package_name: 'Group class +1',
    best_value: 0,
    sessions_per_week: 0.25,
    membership_btn: 'Get Membership'
  },
  {
    heading: 'Unlimited group classes',
    sub_heading: 'Two 1-on-1 session',
    pricing: '$59',
    duration: ' every 4 weeks',
    most_popular: 1,
    uuid: 'd0033044-6cd6-4d18-bd24-6394a90c9018',
    price: 59,
    logname: 'Member - 0.5x per week',
    package_name: 'Group class +2',
    best_value: 0,
    sessions_per_week: 0.5,
    membership_btn: 'Get Membership'
  },
  {
    heading: '',
    sub_heading: 'One 1-on-1 session / week',
    pricing: '$89',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 0,
    uuid: '4d7e7bdd-e966-4708-ba84-09e008411b1f',
    price: 89,
    logname: 'Member - 1x per week',
    package_name: 'One 1-on-1 session/week',
    best_value: 0,
    sessions_per_week: 1,
    membership_btn: 'Get Membership'
  },
  {
    heading: '',
    sub_heading: 'Two 1-on-1 session / week',
    pricing: '$149',
    duration: ' every 4 weeks',
    most_popular: 1,
    uuid: 'c8a2573e-4717-4afa-ace9-5c9e46fee9d2',
    price: 149,
    logname: 'Member - 2x per week',
    package_name: 'Two 1-on-1 sessions/week',
    best_value: 0,
    sessions_per_week: 2,
    membership_btn: 'Get Membership'
  },
  {
    heading: '',
    sub_heading: 'Three 1-on-1 sessions / week',
    pricing: '$219',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 0,
    uuid: 'ec68ac55-892e-4a27-bc3f-cbd1d466754a',
    price: 219,
    logname: 'Member - 3x per week',
    package_name: 'Three 1-on-1 sessions/week',
    best_value: 0,
    sessions_per_week: 3,
    membership_btn: 'Get Membership'
  },
  {
    heading: '',
    sub_heading: 'Four 1-on-1 sessions / week',
    pricing: '$279',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 0,
    uuid: '5b780a96-daeb-49fe-b5fb-e8012a52206d',
    price: 279,
    logname: 'Member - 4x per week',
    package_name: 'Four 1-on-1 sessions/week',
    best_value: 0,
    sessions_per_week: 4,
    membership_btn: 'Get Membership'
  },
  {
    heading: '',
    sub_heading: 'Five 1-on-1 sessions / week',
    pricing: '$339',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 0,
    uuid: '8d8c9e80-62ae-4ff9-b343-f7eb6a25dd80',
    price: 339,
    logname: 'Member - 5x per week',
    package_name: 'Five 1-on-1 sessions/week',
    best_value: 0,
    sessions_per_week: 5,
    membership_btn: 'Get Membership'
  },
  {
    heading: '',
    sub_heading: 'Six 1-on-1 sessions / week',
    pricing: '$399',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 0,
    uuid: '9e64b829-dacf-47bb-b37c-ffe57d92f473',
    price: 399,
    logname: 'Member - 6x per week',
    package_name: 'Six 1-on-1 sessions/week',
    best_value: 0,
    sessions_per_week: 6,
    membership_btn: 'Get Membership'
  },
  {
    heading: '',
    sub_heading: 'Seven 1-on-1 sessions / week',
    pricing: '$459',
    duration: ' every 4 weeks',
    most_popular: 0,
    best_choice: 1,
    uuid: '12156e51-4fbe-41c3-9607-4cd19df3234a',
    price: 459,
    logname: 'Member - 7x per week',
    package_name: 'Seven 1-on-1 sessions/week',
    best_value: 1,
    sessions_per_week: 7,
    membership_btn: 'Get Membership'
  }
];

export const time_day_format = 'ddd, MMM DD hh:mm A z';

export const short_time_day_format = 'MMM DD hh:mm A z';

export const time_format = 'hh:mm A z';

export const day_format = 'ddd, MMM DD';

export const day_format_year = 'ddd, MMM DD, YY';
export const default_timezone = 'America/Los_Angeles';

export const day_format_full_year = 'ddd, MMM DD, YYYY';

export const group_session_status = [
  { value: 'CANCELLED', label: 'CANCELLED' },
  { value: 'FINISHED', label: 'FINISHED' },
  // { value: 'REQUEST_CANCELLATION_FROM_STUDENT', label: 'REQUEST_CANCELLATION_FROM_STUDENT' },
  { value: 'REQUEST_CANCELLATION_FROM_TEACHER', label: 'REQUEST_CANCELLATION_FROM_TEACHER' },
  { value: 'CANCELLED_BY_TEACHER', label: 'CANCELLED_BY_TEACHER' },
  // { value: 'CANCELLED_BY_STUDENT', label: 'CANCELLED_BY_STUDENT' },
  // { value: 'REQUEST_RESCHEDULE_FROM_STUDENT', label: 'REQUEST_RESCHEDULE_FROM_STUDENT' },
  { value: 'REQUEST_RESCHEDULE_FROM_TEACHER', label: 'REQUEST_RESCHEDULE_FROM_TEACHER' },
  { value: 'RESCHEDULED_BY_TEACHER', label: 'RESCHEDULED_BY_TEACHER' },
  { value: 'REPLACEMENT_REJECTED_BY_ALL_TEACHERS', label: 'REPLACEMENT_REJECTED_BY_ALL_TEACHERS' },
  { value: 'NO_SHOW_CANCELLATION_1HOUR', label: 'NO_SHOW_CANCELLATION_1HOUR' },
  { value: 'TEACHER_NO_SHOW', label: 'TEACHER_NO_SHOW' },
  // { value: 'STUDENT_NO_SHOW', label: 'STUDENT_NO_SHOW' },
  // { value: 'STUDENT_AND_TEACHER_NO_SHOW', label: 'STUDENT_AND_TEACHER_NO_SHOW' },
  { value: 'NO_SHOW_WITH_RECORDING', label: 'NO_SHOW_WITH_RECORDING' },
  { value: 'JOINED_BUT_CANCELLED', label: 'JOINED_BUT_CANCELLED' }
];

export const cancelled_status = [
  'CANCELLED',
  'CANCELLED_BY_TEACHER',
  'CANCELLED_BY_STUDENT',
  'NO_SHOW_CANCELLATION_1HOUR',
  'TEACHER_NO_SHOW',
  'STUDENT_NO_SHOW',
  'STUDENT_AND_TEACHER_NO_SHOW',
  'JOINED_BUT_CANCELLED',
  'NO_SHOW_WITH_RECORDING'
];

export const choice_active = [
  { label: 'True', value: 1 },
  { label: 'False', value: 0 }
];

export const engineering_student_timeline_msg_types = [
  { label: 'API REQUEST', value: 'API_REQUEST' },
  { label: 'API RESPONSE', value: 'API_RESPONSE' },
  { label: 'EMAIL SENT', value: 'EMAIL_SENT' },
  { label: 'SMS SENT', value: 'SMS_SENT' },
  { label: 'SMS RECEIVED', value: 'SMS_RECEIVED' },
  { label: 'UI EVENTS', value: 'UI_EVENTS' }
];

export const other_student_timeline_msg_types = [
  { label: 'EMAIL SENT', value: 'EMAIL_SENT' },
  { label: 'SMS SENT', value: 'SMS_SENT' },
  { label: 'SMS RECEIVED', value: 'SMS_RECEIVED' }
];

export const audience_types = [
  { label: 'General', value: 0 },
  { label: 'Kids', value: 1 },
  { label: 'Seniors', value: 2 },
  { label: 'Women', value: 3 },
  { label: 'Beginners Bootcamp', value: 4 }
];

export const invalidations = [
  { label: 'Articles', value: '/articles' },
  { label: 'Pricing', value: '/pricing' }
];

export const split_test_categories = [
  { label: 'General', value: 'GENERAL' },
  { label: 'Onboarding', value: 'ONBOARDING' },
  { label: 'Landing Page', value: 'LANDING_PAGE' }
];

export const split_test_status = [
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Live', value: 'LIVE' },
  { label: 'Archive', value: 'ARCHIVED' }
];

export const inventory_types = [
  { label: 'General', value: 'GENERAL' },
  { label: 'Mobile', value: 'MOBILE' },
  { label: 'Laptop', value: 'LAPTOP' },
  { label: 'Book', value: 'BOOK' }
];

export const phone_no_codes = [
  { label: 'UK', value: '44' },
  { label: 'USA', value: '1' },
  { label: 'Algeria', value: '213' },
  { label: 'Andorra', value: '376' },
  { label: 'Angola', value: '244' },
  { label: 'Anguilla', value: '1264' },
  { label: 'Antigua & Barbuda', value: '1268' },
  { label: 'Argentina', value: '54' },
  { label: 'Armenia', value: '374' },
  { label: 'Aruba', value: '297' },
  { label: 'Australia', value: '61' },
  { label: 'Austria', value: '43' },
  { label: 'Azerbaijan', value: '994' },
  { label: 'Bahamas', value: '1242' },
  { label: 'Bahrain', value: '973' },
  { label: 'Bangladesh', value: '880' },
  { label: 'Barbados', value: '1246' },
  { label: 'Belarus', value: '375' },
  { label: 'Belgium', value: '32' },
  { label: 'Belize', value: '501' },
  { label: 'Benin', value: '229' },
  { label: 'Bermuda', value: '1441' },
  { label: 'Bhutan', value: '975' },
  { label: 'Bolivia', value: '591' },
  { label: 'Bosnia Herzegovina', value: '387' },
  { label: 'Botswana', value: '267' },
  { label: 'Brazil', value: '55' },
  { label: 'Brunei', value: '673' },
  { label: 'Bulgaria', value: '359' },
  { label: 'Burkina Faso', value: '226' },
  { label: 'Burundi', value: '257' },
  { label: 'Cambodia', value: '855' },
  { label: 'Cameroon', value: '237' },
  { label: 'Canada', value: '1' },
  { label: 'Cape Verde Islands', value: '238' },
  { label: 'Cayman Islands', value: '1345' },
  { label: 'Central African Republic', value: '236' },
  { label: 'Chile', value: '56' },
  { label: 'China', value: '86' },
  { label: 'Colombia', value: '57' },
  { label: 'Comoros', value: '269' },
  { label: 'Congo', value: '242' },
  { label: 'Cook Islands', value: '682' },
  { label: 'Costa Rica', value: '506' },
  { label: 'Croatia', value: '385' },
  { label: 'Cuba', value: '53' },
  { label: 'Cyprus North', value: '90392' },
  { label: 'Cyprus South', value: '357' },
  { label: 'Czech Republic', value: '42' },
  { label: 'Denmark', value: '45' },
  { label: 'Djibouti', value: '253' },
  { label: 'Dominica', value: '1809' },
  { label: 'Dominican Republic', value: '1809' },
  { label: 'Ecuador', value: '593' },
  { label: 'Egypt', value: '20' },
  { label: 'El Salvador', value: '503' },
  { label: 'Equatorial Guinea', value: '240' },
  { label: 'Eritrea', value: '291' },
  { label: 'Estonia', value: '372' },
  { label: 'Ethiopia', value: '251' },
  { label: 'Falkland Islands', value: '500' },
  { label: 'Faroe Islands', value: '298' },
  { label: 'Fiji', value: '679' },
  { label: 'Finland', value: '358' },
  { label: 'France', value: '33' },
  { label: 'French Guiana', value: '594' },
  { label: 'French Polynesia', value: '689' },
  { label: 'Gabon', value: '241' },
  { label: 'Gambia', value: '220' },
  { label: 'Georgia', value: '7880' },
  { label: 'Germany', value: '49' },
  { label: 'Ghana', value: '233' },
  { label: 'Gibraltar', value: '350' },
  { label: 'Greece', value: '30' },
  { label: 'Greenland', value: '299' },
  { label: 'Grenada', value: '1473' },
  { label: 'Guadeloupe', value: '590' },
  { label: 'Guam', value: '671' },
  { label: 'Guatemala', value: '502' },
  { label: 'Guinea', value: '224' },
  { label: 'Guinea - Bissau', value: '245' },
  { label: 'Guyana', value: '592' },
  { label: 'Haiti', value: '509' },
  { label: 'Honduras', value: '504' },
  { label: 'Hong Kong', value: '852' },
  { label: 'Hungary', value: '36' },
  { label: 'Iceland', value: '354' },
  { label: 'India', value: '91' },
  { label: 'Indonesia', value: '62' },
  { label: 'Iran', value: '98' },
  { label: 'Iraq', value: '964' },
  { label: 'Ireland', value: '353' },
  { label: 'Israel', value: '972' },
  { label: 'Italy', value: '39' },
  { label: 'Jamaica', value: '1876' },
  { label: 'Japan', value: '81' },
  { label: 'Jordan', value: '962' },
  { label: 'Kazakhstan', value: '7' },
  { label: 'Kenya', value: '254' },
  { label: 'Kiribati', value: '686' },
  { label: 'Korea North', value: '850' },
  { label: 'Korea South', value: '82' },
  { label: 'Kuwait', value: '965' },
  { label: 'Kyrgyzstan', value: '996' },
  { label: 'Laos', value: '856' },
  { label: 'Latvia', value: '371' },
  { label: 'Lebanon', value: '961' },
  { label: 'Lesotho', value: '266' },
  { label: 'Liberia', value: '231' },
  { label: 'Libya', value: '218' },
  { label: 'Liechtenstein', value: '417' },
  { label: 'Lithuania', value: '370' },
  { label: 'Luxembourg', value: '352' },
  { label: 'Macao', value: '853' },
  { label: 'Macedonia', value: '389' },
  { label: 'Madagascar', value: '261' },
  { label: 'Malawi', value: '265' },
  { label: 'Malaysia', value: '60' },
  { label: 'Maldives', value: '960' },
  { label: 'Mali', value: '223' },
  { label: 'Malta', value: '356' },
  { label: 'Marshall Islands', value: '692' },
  { label: 'Martinique', value: '596' },
  { label: 'Mauritania', value: '222' },
  { label: 'Mayotte', value: '269' },
  { label: 'Mexico', value: '52' },
  { label: 'Micronesia', value: '691' },
  { label: 'Moldova', value: '373' },
  { label: 'Monaco', value: '377' },
  { label: 'Mongolia', value: '976' },
  { label: 'Montserrat', value: '1664' },
  { label: 'Morocco', value: '212' },
  { label: 'Mozambique', value: '258' },
  { label: 'Myanmar', value: '95' },
  { label: 'Namibia', value: '264' },
  { label: 'Nauru', value: '674' },
  { label: 'Nepal', value: '977' },
  { label: 'Netherlands', value: '31' },
  { label: 'New Caledonia', value: '687' },
  { label: 'New Zealand', value: '64' },
  { label: 'Nicaragua', value: '505' },
  { label: 'Niger', value: '227' },
  { label: 'Nigeria', value: '234' },
  { label: 'Niue', value: '683' },
  { label: 'Norfolk Islands', value: '672' },
  { label: 'Northern Marianas', value: '670' },
  { label: 'Norway', value: '47' },
  { label: 'Oman', value: '968' },
  { label: 'Palau', value: '680' },
  { label: 'Panama', value: '507' },
  { label: 'Papua New Guinea', value: '675' },
  { label: 'Paraguay', value: '595' },
  { label: 'Peru', value: '51' },
  { label: 'Philippines', value: '63' },
  { label: 'Poland', value: '48' },
  { label: 'Portugal', value: '351' },
  { label: 'Puerto Rico', value: '1787' },
  { label: 'Qatar', value: '974' },
  { label: 'Reunion', value: '262' },
  { label: 'Romania', value: '40' },
  { label: 'Russia', value: '7' },
  { label: 'Rwanda', value: '250' },
  { label: 'San Marino', value: '378' },
  { label: 'Sao Tome & Principe', value: '239' },
  { label: 'Saudi Arabia', value: '966' },
  { label: 'Senegal', value: '221' },
  { label: 'Serbia', value: '381' },
  { label: 'Seychelles', value: '248' },
  { label: 'Sierra Leone', value: '232' },
  { label: 'Singapore', value: '65' },
  { label: 'Slovak Republic', value: '421' },
  { label: 'Slovenia', value: '386' },
  { label: 'Solomon Islands', value: '677' },
  { label: 'Somalia', value: '252' },
  { label: 'South Africa', value: '27' },
  { label: 'Spain', value: '34' },
  { label: 'Sri Lanka', value: '94' },
  { label: 'St. Helena', value: '290' },
  { label: 'St. Kitts', value: '1869' },
  { label: 'St. Lucia', value: '1758' },
  { label: 'Sudan', value: '249' },
  { label: 'Suriname', value: '597' },
  { label: 'Swaziland', value: '268' },
  { label: 'Sweden', value: '46' },
  { label: 'Switzerland', value: '41' },
  { label: 'Syria', value: '963' },
  { label: 'Taiwan', value: '886' },
  { label: 'Tajikstan', value: '7' },
  { label: 'Thailand', value: '66' },
  { label: 'Togo', value: '228' },
  { label: 'Tonga', value: '676' },
  { label: 'Trinidad & Tobago', value: '1868' },
  { label: 'Tunisia', value: '216' },
  { label: 'Turkey', value: '90' },
  { label: 'Turkmenistan', value: '7' },
  { label: 'Turkmenistan', value: '993' },
  { label: 'Turks & Caicos Islands', value: '1649' },
  { label: 'Tuvalu', value: '688' },
  { label: 'Uganda', value: '256' },
  { label: 'Ukraine', value: '380' },
  { label: 'United Arab Emirates', value: '971' },
  { label: 'Uruguay', value: '598' },
  { label: 'Uzbekistan', value: '7' },
  { label: 'Vanuatu', value: '678' },
  { label: 'Vatican City', value: '379' },
  { label: 'Venezuela', value: '58' },
  { label: 'Vietnam', value: '84' },
  { label: 'Virgin Islands - British', value: '1284' },
  { label: 'Virgin Islands - US', value: '1340' },
  { label: 'Wallis & Futuna', value: '681' },
  { label: 'Yemen North', value: '969' },
  { label: 'Yemen South', value: '967' },
  { label: 'Zambia', value: '260' },
  { label: 'Zimbabwe', value: '263' }
];

export const teacher_reco_scores = [
  { value: 'reco_for_trial', label: 'Reco for Trial' },
  { value: 'reco_for_members', label: 'Reco for Members' }
];

export const inactive_past_inactive_status = [
  { label: 'Inactive', value: 'current_inactive=1' },
  { label: 'Past Inactive', value: 'current_inactive=0' }
];

export const client_domain = [
  { label: 'MyyogaTeacher', value: 'lifestyle' },
  { label: 'LifetreeHealth', value: 'backpain' }
];

export const magic_link_campaigns_redirect_path = [
  { label: 'Dashboard', value: 'dashboard' },
  { label: 'Pricing', value: 'pricing' },
  { label: 'Better Back Beta', value: 'better-back-beta' },
  { label: 'Articles', value: 'articles' },
  { label: 'My Sessions', value: 'my-sessions' },
  { label: 'Group Classes', value: 'group-classes' },
  { label: 'Upgrade Annual Plan', value: 'upgrade-to-annual-plan' },
  { label: '1-on-1 Packs', value: '1on1-packs' },
  { label: 'lto75-3', value: 'membership/lto75-3' },
  { label: '1-on-1', value: '1-on-1' },
  { label: 'lto', value: 'membership/lto' },
  { label: 'lto75-2', value: 'membership/lto75-2' },
  { label: 'lto75-1', value: 'membership/lto75-1' },
  { label: 'lto50-1', value: 'membership/lto50-1' },
  { label: 'Others', value: 'others' }
];

export const feedbackFormType = [{ label: 'Qa Score Card', value: 'QA_SCORE_CARD' }];

export const feedbackFormQuestionType = [
  { label: 'Text', value: 'TEXT' },
  { label: 'Single Select', value: 'SINGLE_SELECT' },
  { label: 'Yes or No', value: 'YES_OR_NO' },
  { label: 'Multiple Select', value: 'MULTIPLE_SELECT' },
  { label: 'Rating Scale', value: 'RATING_SCALE' }
];

export const yesOrNo = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const feedbackQuestionTypeHash = {
  TEXT: 'Text',
  SINGLE_SELECT: 'Single Select',
  YES_OR_NO: 'Yes or No',
  MULTIPLE_SELECT: 'Multiple Select',
  RATING_SCALE: 'Rating Scale'
};

export const feedbackFormTypeHash = {
  QA_SCORE_CARD: 'Qa Score Card'
};

export const yesOrNoHash = {
  true: 'Yes',
  false: 'No'
};

export const clientDomainHash = {
  lifestyle: 'MyyogaTeacher',
  backpain: 'LifetreeHealth'
};

export const tickImageUrl = 'https://images.myyogateacher.com/icons/ic-checkmark-48.png';
export const closeImageUrl = 'https://images.myyogateacher.com/icons/ic-delete-48.png';

export const newUsersDownloadList = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'UUID', value: 'uuid' },
  { label: 'Created Date', value: 'created_date' },
  { label: 'Funnel Url', value: 'funnel_url' },
  { label: 'Offer', value: 'offer_type' },
  { label: 'Funnel', value: 'funnel_type' },
  { label: 'Device', value: 'device_type' },
  { label: 'Yoga Level', value: 'yoga_level' },
  { label: 'Trial End Date', value: 'trial_end_date' },
  { label: 'Affiliate', value: 'affiliate' },
  { label: 'Queue Affiliate', value: 'queue_affiliate' },
  { label: 'Credits', value: 'credits' },
  { label: 'Concierge', value: 'concierge_name' },
  { label: 'Member', value: 'is_member' },
  { label: 'phone No', value: 'phone_personal' },
  { label: 'Yoga Sessions Finished', value: 'yoga_sessions_finshed' },
  { label: 'Group Sessions Finished', value: 'group_sessions_finshed' },
  { label: 'Yoga Sessions Scheduled', value: 'yoga_sessions_scheduled' },
  { label: 'Group Sessions Scheduled', value: 'group_sessions_scheduled' },
  { label: 'Activity Score', value: 'activity_score' },
  { label: 'Onboarding Score', value: 'onboarding_score' },
  { label: 'Iana Timezone', value: 'iana_timezone' },
  { label: 'Onboarding Complete', value: 'onboarding_complete' },
  { label: 'Age', value: 'client_age' }
];
export const HridayaLinkType = 'HRIDAYA_SHORT_URL';
export const HridayaMedium = 'HRIDAYA_ORIGIN';

export const EthnicityOptions = [
  { label: 'Indian', value: 'INDIAN' },
  { label: 'Western', value: 'WESTERN' },
  { label: 'Not Sure', value: 'UNSURE' }
];

export const lineCoach = {
  all: 'all',
  first_line: 'first_line',
  second_line: 'second_line',
  third_line: 'third_line'
};

export const FirstSecondThirdLineCoach = [
  { value: lineCoach.all, label: 'ALL' },
  { value: lineCoach.first_line, label: 'FIRST LINE COACH' },
  { value: lineCoach.second_line, label: 'SECOND LINE COACH' },
  { value: lineCoach.third_line, label: 'THIRD LINE COACH' }
];

export const StudentType = {
 Trial: "trial",
 Member: "member",
 All: "all"
}