import React, { Component } from 'react';
import { connect } from 'react-redux';
import './sms.css';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import * as actions from '../../../../redux/action_creators';
import SMSHandler from '../../../../../myt_chat/src/chat/network/SMSHandler';
import moment from 'moment';
import ChatClient from '../../../../../myt_chat/src/chat/network/ChatClient';
import ConnectionState from '../../../../../myt_chat/src/chat/enums/ConnectionState';
import { CallbackHandler } from '../../../../../myt_chat/src/chat/callbacks/handler';
import { SMSChannelPkt } from '../../../../../myt_chat/src/chat/packets/SMSChannel';
import UserRole from '../../../../../myt_chat/src/chat/enums/UserRole';
import { WhatsappIcon, SMSIcon } from '../../../../utils/svg';

class SmsWindow extends Component {
  messageEnd = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      is_loading: false,
      add_student_uuid: '',
      sms_data_of_user: [],
      new_sms: '',
      student_name: '',
      sending_sms: false,
      timeLeft: '24:00:00',
      showTimer: false,
      messageDispatcher: '',
      replied_time: ''
    };
  }
  timerInterval: number | null = null;

  smsHandler = (item: SMSChannelPkt) => {
    console.log('smswindow->smshandler->', JSON.stringify(item));
    if (!item.channel.endsWith(this.state.add_student_uuid)) {
      return;
    }

    const smsItem = {
      uuid: item.id,
      to_student: item.to.role === UserRole.STUDENT ? 1 : 0,
      sms_timestamp: moment.utc(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      sms_body: item.text,
      sender_name: item.from.name,
      message_type: item.from.type,
      status: item.status,
      sms_epoch_time: item.timestamp
    };

    const indexedElement = this.state.sms_data_of_user.find((x) => {
      return x.uuid === smsItem.uuid;
    });

    if (indexedElement !== undefined) {
      return;
    }

    const newItems = [...this.state.sms_data_of_user];

    newItems.push(smsItem);

    this.setState(
      {
        sms_data_of_user: newItems
      },
      () => {
        this.scrollToBottom();
      }
    );
  };
  componentDidMount() {
    this.setState(
      {
        add_student_uuid: this.props.student_uuid,
        student_name: this.props.student_name,
        original_message_uuid: this.props.original_message_uuid
      },
      () => {
        this.load_data();
      }
    );

    CallbackHandler.shared.registerSMSHandler(this.smsHandler);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.student_uuid === this.props.student_uuid) {
      return;
    }

    this.setState(
      {
        add_student_uuid: this.props.student_uuid,
        student_name: this.props.student_name,
        original_message_uuid: this.props.original_message_uuid
      },
      () => {
        this.load_data();
      }
    );
  }

  componentWillUnmount() {
    CallbackHandler.shared.unregisterSMSHandler(this.smsHandler);
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
  isAnyMessageSentByStudent = () => {
    const messages = this.state.sms_data_of_user || [];
    if (messages.length === 0) return false; // No messages available

    return (
      this.props.message_dispatch_method === 'whatsapp' &&
      messages.some((mess: any) => mess.to_student === 0 && mess.message_type === 'whatsapp')
    );
  };
  render_timer = () => {
    const messages = this.state.sms_data_of_user;

    const lastMessageToStudent = [...messages]
      .reverse()
      .find((msg) => msg.to_student === 0 && msg.message_type === 'whatsapp');

    let shouldShowTimer = false;

    if (lastMessageToStudent) {
      const createdTime = lastMessageToStudent.sms_epoch_time;
      const expirationTime = createdTime + 24 * 60 * 60 * 1000;
      const currentTime = Date.now();

      shouldShowTimer =
        this.props.message_dispatch_method === 'whatsapp' &&
        currentTime < expirationTime &&
        this.isAnyMessageSentByStudent();
    }

    if (!shouldShowTimer) {
      return null;
    }

    return (
      <div>
        <div className='className="border-t border-b border-[#FFDCDC] bg-[#FFF0F0] px-[15px] py-[14px]'>
          <div className="text-[#FF3131] text-center font-inter text-[13px] font-medium leading-[14px]">
            Time left to reply {this.state.timeLeft}
          </div>
        </div>
      </div>
    );
  };

  load_data = () => {
    if (!!!this.state.add_student_uuid) {
      return;
    }

    const connectionListener = (state: ConnectionState) => {
      if (state !== ConnectionState.AUTHENTICATED) {
        CallbackHandler.shared.registerConnectionListener(connectionListener);
        return;
      }

      CallbackHandler.shared.unregisterConnectionListener(connectionListener);

      this.setState({
        is_loading: true
      });

      SMSHandler.shared.fetchSMSArchive(this.state.add_student_uuid).then((result) => {
        let items = result.messages.map((item) => {
          return {
            uuid: item.id,
            to_student: item.toStudent ? 1 : 0,
            sms_timestamp: moment.utc(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
            sms_body: item.text,
            sender_name: item.profile.name,
            message_type: item.type,
            status: item.status,
            sms_epoch_time: item.timestamp
          };
        });

        this.setState(
          {
            sms_data_of_user: items
          },
          () => {
            this.startTimer();
          }
        );

        this.scrollToBottom();
      });
    };

    connectionListener(ChatClient.shared.connectionState());
  };

  send_success_notification = () => {
    try {
      this.props.send_success_notification?.();
    } catch (e) {
      console.log('smswindow->send_success_notification->', e);
    }
  };

  reply_user = (message_method = 'sms', is_predefined_msg = false) => {
    this.setState({ sending_sms: true }, () => {
      let sms_url = '/v2/notifications/twilio/sms';
      let whatsapp_url = '/v2/notifications/twilio/send_message';
      let api_url = message_method === 'sms' ? sms_url : whatsapp_url;
      const url = get_api_url(api_url, false, true);
      let payload;
      if (message_method === 'sms') {
        payload = {
          to_student_uuid: this.state.add_student_uuid,
          body: this.state.new_sms,
          timeline_event: 'hridaya-student-sms',
          original_message_uuid: this.props.original_message_uuid
        };
        if (!!this.state.original_message_uuid) {
          payload.original_message_uuid = this.state.original_message_uuid;
        }
      } else if (message_method === 'whatsapp') {
        payload = {
          to_student_uuid: this.state.add_student_uuid,
          message: this.state.new_sms,
          sms_template_tag: is_predefined_msg ? 'predefined_message_for_care' : '',
          message_medium_type: 'WHATSAPP',
          original_message_uuid:
            this.props.original_message_uuid != undefined ? this.props.original_message_uuid : ''
        };
      }

      post_api(url, payload, true)
        .then((response) => {
          if (response?.data?.status === 'failure') {
            let err_message;
            try {
              if (response?.data?.code === 424) {
                err_message = 'Message too long. please reduce the size';
              } else {
                err_message = response?.data?.reason;
              }
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ is_loading: false, sending_sms: false });
          } else {
            this.setState({ new_sms: '', sending_sms: false });
            this.send_success_notification();
            this.load_data();
          }
        })
        .catch((e) => {
          console.log('smswindow->reply_user->', e);
          let err_message;
          try {
            err_message = e.response?.data?.reason || e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ is_loading: false, sending_sms: false });
        });
    });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    this.setState({ [key]: value });
  };

  scrollToBottom = () => {
    try {
      const height = this.messageEnd.current.scrollHeight;
      this.messageEnd.current.scrollTo(0, height);
    } catch (e) {
      console.log('smswindow->scrollToBottom->', e);
    }
  };

  render_sms = () => {
    const singleTick = 'https://images.myyogateacher.com/icons/single-sent-tick.svg';
    const doubleTick = 'https://images.myyogateacher.com/icons/grey-read-tick.svg';
    const blueTick = 'https://images.myyogateacher.com/web_images/double_tick.svg';

    const statusImages = [singleTick, doubleTick, blueTick];

    return (
      <div className="c-c-messages" ref={this.messageEnd}>
        {this.state.sms_data_of_user.map((sms, uuid) => {
          return (
            <div className={sms.to_student == 1 ? 'my-sms' : 'friend-sms'} key={uuid}>
              <div className="chat-cmt-box">
                <span>{sms.sms_body}</span>
                <br />
                <div className={sms.to_student == 1 ? 'sms-time chat-me' : 'sms-time'}>
                  <div>{sms.sms_timestamp}</div>
                  <div className="flex gap-2">
                    <div>{sms.message_type === 'whatsapp' ? <WhatsappIcon /> : <SMSIcon />}</div>
                    <div>{sms.sender_name}</div>
                  </div>
                  <div>
                    {sms.to_student === 1 && sms.status >= 0 && sms.status <= 2 && (
                      <img
                        src={statusImages[sms.status]}
                        alt={`Status ${sms.status}`}
                        width="20"
                        height="20"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end"></div>
            </div>
          );
        })}
      </div>
    );
  };

  render_header = () => {
    return (
      <div className="c-c-header">
        <div className="c-c-header-c">
          <span>{this.state.student_name}</span>
        </div>
        <div className="c-c-header-i">
          <button className="chat-c-btn last-btn" onClick={this.props.close_fn}>
            <img
              className="close_min_chat"
              src="https://images.myyogateacher.com/ic_cross_ch1.svg"
              alt="c"
            />
          </button>
        </div>
      </div>
    );
  };

  render_predefined_message_btn = () => {
    return (
      <div
        className="btn btn-primary cursor-pointer w-full text-13.5px h-38px"
        onClick={() => this.reply_user('whatsapp', true)}
      >
        Send predefined message
      </div>
    );
  };

  render_footer = () => {
    return (
      <div className="c-c-i-c">
        {this.props.message_dispatch_method !== 'whatsapp' ||
        (this.state.timeLeft !== '00:00:00' && this.isAnyMessageSentByStudent()) ? (
          <div className="textarea-cont">
            <textarea
              className="textarea"
              value={this.state.new_sms}
              name="new_sms"
              onChange={this.onCommonChange}
            >
              {' '}
            </textarea>
            <span
              className={`send-btn-c ch_send_btn ${this.props.message_dispatch_method === 'whatsapp' ? 'whatsapp_bg' : 'sms_bg'}`}
              onClick={() =>
                this.reply_user(this.props.message_dispatch_method === 'sms' ? 'sms' : 'whatsapp')
              }
            >
              {this.props.message_dispatch_method === 'sms' ? 'Send SMS' : 'Send WA'}
            </span>
          </div>
        ) : (
          this.render_predefined_message_btn()
        )}
      </div>
    );
  };
  startTimer = () => {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    const message = this.state.sms_data_of_user;

    const lastMessageToStudent = [...message].reverse().find((msg) => msg.to_student === 0);

    let createdTime = Date.now();
    if (lastMessageToStudent) {
      createdTime = lastMessageToStudent.sms_epoch_time;
    }

    const expirationTime = createdTime + 24 * 60 * 60 * 1000;
    console.log('Expiration Time:', new Date(expirationTime).toLocaleString());

    const updateTimer = () => {
      const currentTime = Date.now();
      const remainingTime = expirationTime - currentTime;

      if (remainingTime <= 0) {
        this.setState({
          timeLeft: '00:00:00',
          is_loading: false
        });
        clearInterval(this.timerInterval);
        return;
      }

      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      this.setState({
        timeLeft: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,
        is_loading: false
      });
    };

    updateTimer();
    this.timerInterval = setInterval(updateTimer, 1000);
  };

  render() {
    return this.state.is_loading ? (
      <CustomLoading />
    ) : (
      <div className="sms-window ch_new">
        {this.render_header(this.state.student_name)}
        <div className="c-c-content">
          {this.render_timer()}
          {this.render_sms()}
          {this.render_footer()}
        </div>
        {this.state.sending_sms ? <CustomLoading full_loader={true} /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    iana_timezone: state.home.iana_timezone
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsWindow);
