import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import CustomLoading from '../../util_components/custom_loading';
import CommonHeader from '../../util_components/common_header';
import * as actions from '../../../redux/action_creators';
import CustomModal from '../../util_components/custom_modal';
import CustomInput from '../../util_components/custom_input';
import { get_api_url } from '../../../utils/urls';
import { get_api } from '../../../redux/api_funcs';
import HighlightSearch from '../../util_components/highlight_search_text';
import EventViewer from './EventViewer';
import { day_format_full_year, time_format } from '../../../constants';
import { FrameWrapper } from './FrameWrapper';
import StatusCodeLegend from './StatusCodeLegend';

function EmailTimeline() {
  const [loading, setLoading] = useState(false);
  const [showStatusLog, setShowStatusLog] = useState(false);
  const [showRawData, setShowRawData] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userUUID, setUserUUID] = useState('');
  const [timelineData, setTimelineData] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [rawDataSearchInput, setRawDataSearchInput] = useState('');
  const [pageTitle, setPageTitle] = useState('Emails timeline');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userGeoInfos, setUserGeoInfos] = useState({});
  const [selectedGeoIndex, setSelectedGeoIndex] = useState(-1);

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const iana_timezone = useSelector((state) => state.home.iana_timezone);

  const goToPath = (path) => history.push(path);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    loadData(page);
  };

  const fetchUserGeoInfo = async (ip, index) => {
    if (userGeoInfos[ip]) {
      setSelectedGeoIndex(index);
      return;
    }
    const url = `https://geo-tag.azure-services.myyogateacher.com/ip?ip=${ip}`;

    get_api(url).then((result) => {
      setUserGeoInfos((prevGeoInfos) => ({
        ...prevGeoInfos,
        [ip]: result.data
      }));
      setSelectedGeoIndex(index);
    });
  };

  const sortData = (data = []) => {
    // Sort timelineData by created_date in descending order
    const sortedData = data.slice().sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    return sortedData;
  };

  const loadData = (pageNo = 1) => {
    try {
      if (userUUID !== '' && userRole !== '') {
        setLoading(true);
        const url = get_api_url(
          `/v2/notifications/cms/email/timeline?uuid=${userUUID}&role=${userRole}&page_no=${pageNo}`,
          false,
          true
        );

        get_api(url, true)
          .then((res) => {
            const to_address = res?.data?.data[0]?.email_details?.to_address;
            if (to_address) {
              const index = to_address?.indexOf('<');
              if (index !== -1) {
                const extractedName = to_address.slice(0, index).trim();
                setPageTitle(`Emails timeline for ${userRole} ${extractedName}`);
              }
            }
            setTimelineData(sortData(res?.data?.data || []));
            setTotalPages(res?.data?.total_pages);
            setLoading(false);
          })
          .catch((err) => {
            const defaultError = 'Some error occured. Please contact dev team.';
            let err_message;
            try {
              err_message = err?.response?.data?.error ?? defaultError;
            } catch (err) {
              err_message = defaultError;
            }
            setNotificationVariable(true, 'error', err_message);
            setLoading(false);
          });
      }
    } catch (e) {
      setNotificationVariable(true, 'error', err_message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const extractUserRoleAndUUID = () => {
      const splitPath = location.pathname?.split('/');
      if (splitPath?.[2] && splitPath?.[3]) {
        setUserRole(splitPath[2]);
        setUserUUID(splitPath[3]);
        setLoading(true);
      } else {
        goToPath('/');
      }
    };
    try {
      extractUserRoleAndUUID();
    } catch {
      goToPath('/');
    }
  }, [location.pathname]);

  useEffect(() => {
    loadData();
  }, [userUUID, userRole]);

  const toggleEmailTrack = (idx) => {
    setSelectedIdx(idx);
    console.log('idx: ', idx);
    setShowStatusLog(!showStatusLog);
  };

  const toggleRawData = (idx) => {
    setSelectedIdx(idx);
    setShowRawData(!showRawData);
    if (!showRawData) {
      setRawDataSearchInput('');
    }
  };

  const toggleShowEmail = (idx) => {
    setSelectedIdx(idx);
    setShowEmail(!showEmail);
  };

  const handleRawdataSearch = (e) => {
    const { value } = e.target;
    setRawDataSearchInput(value);
  };

  const renderRawData = () => {
    if (showRawData) {
      const { raw_data } = timelineData[selectedIdx];
      if (raw_data) {
        const object_keys = Object.keys(raw_data).sort();
        const filtered_keys = object_keys.filter(
          (k) => k.toLowerCase().indexOf(rawDataSearchInput.toLowerCase()) !== -1
        );

        return (
          <div style={{ height: '80vh' }}>
            <CustomInput
              label="Search key value"
              style={{ width: '500px' }}
              name="rawDataSearchInput"
              onChange={handleRawdataSearch}
            />
            <div className="cus-table" style={{ marginBottom: '20px' }}>
              <div className="ct-row ct-h">
                <div className="ct-col ct-hf ct-la ct-pl-10">Key</div>
                <div className="ct-col ct-la ct-pl-10">Value</div>
              </div>
              {filtered_keys.map((item, index) => (
                <div className="ct-row" key={index}>
                  <div className="ct-col ct-hf ct-bw ct-sm-font ct-la ct-pl-10">
                    <HighlightSearch
                      full_text={item}
                      search_text={rawDataSearchInput?.toLowerCase()}
                    />
                  </div>
                  <div className="ct-col ct-bw ct-sm-font ct-la ct-pl-10">
                    {raw_data[item] !== '' && raw_data[item] !== null ? raw_data[item] : '-'}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
      return <center>No raw data found</center>;
    }
  };

  const renderEmailTrack = () => {
    if (showStatusLog) {
      const { email_trail } = timelineData[selectedIdx];
      if (email_trail) {
        return (
          <div style={{ height: '80vh', position: 'relative' }}>
            <div style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
              <StatusCodeLegend />
            </div>
            <h1>
              <center>Tracking details</center>
            </h1>
            <div className="max-w-xl mx-auto p-8">
              <div className="relative">
                {email_trail.map((item, index) => (
                  <div key={index} className="flex items-baseline last:mb-0">
                    <div className="flex-1 text-xs text-right pr-8">
                      {moment(item.time).tz(iana_timezone).format(day_format_full_year)}{' '}
                      {moment(item.time).tz(iana_timezone).format(time_format)}
                    </div>
                    <div className="relative flex-1 pl-8 text-sm  pb-6">
                      <p style={{ color: item.color, fontWeight: 600 }}>{item.status}</p>
                      <ul className="mt-2 text-xs">
                        {item?.ip_address ? (
                          <li>
                            <b>IP Address:</b> {item.ip_address}
                          </li>
                        ) : (
                          ''
                        )}
                        {item?.user_agent ? (
                          <li>
                            <b>User Agent:</b> {item.user_agent}
                          </li>
                        ) : (
                          ''
                        )}
                        {userGeoInfos[item.ip_address] ? (
                          <>
                            {userGeoInfos[item.ip_address]?.cn_iso ? (
                              <li>
                                <b>Country code:</b> {userGeoInfos[item.ip_address].cn_iso}
                              </li>
                            ) : (
                              ''
                            )}
                            {userGeoInfos[item.ip_address]?.cn_nm ? (
                              <li>
                                <b>City name:</b> {userGeoInfos[item.ip_address].cn_nm}
                              </li>
                            ) : (
                              ''
                            )}
                            {userGeoInfos[item.ip_address]?.ct_nm ? (
                              <li>
                                <b>Country name:</b> {userGeoInfos[item.ip_address].ct_nm}
                              </li>
                            ) : (
                              ''
                            )}
                            {userGeoInfos[item.ip_address]?.geo ? (
                              <li>
                                <b>Latitude & Longitude:</b> {userGeoInfos[item.ip_address].geo}
                              </li>
                            ) : (
                              ''
                            )}
                            {userGeoInfos[item.ip_address]?.tz ? (
                              <li>
                                <b>Timezone:</b> {userGeoInfos[item.ip_address].tz}
                              </li>
                            ) : (
                              ''
                            )}
                          </>
                        ) : null}
                        {item?.ip_address && !userGeoInfos[item.ip_address] ? (
                          <div
                            className="relative inline-block group"
                            onClick={() => fetchUserGeoInfo(item.ip_address, index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="cursor-pointer"
                            >
                              <g fill="none" stroke="red" strokeWidth="2">
                                <path d="M12 2C8.134 2 5 5.134 5 8c0 4.5 7 11 7 11s7-6.5 7-11c0-2.866-3.134-6-7-6z" />
                                <circle cx="12" cy="8" r="2" fill="red" />
                              </g>
                            </svg>
                            <span className="absolute bottom-[-30px] left-0 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 text-sm bg-black text-white px-2 py-1 rounded-md whitespace-nowrap transition-opacity">
                              Fetch user geo details
                            </span>
                          </div>
                        ) : null}
                      </ul>
                      <div className="absolute top-[17px] -left-[7px] h-full">
                        <div
                          className="w-4 h-4 rounded-full"
                          style={{ backgroundColor: item.color }}
                        />
                        {index !== email_trail.length - 1 && (
                          <div
                            className={`absolute w-px h-full ${index === email_trail.length - 1 ? '' : 'bg-gray-300'} left-2 top-4`}
                           />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const renderEmailView = () => {
    if (showEmail) {
      const { email_details, email_tag, raw_data } = timelineData[selectedIdx];
      if (email_details) {
        return (
          <div style={{ height: '80vh' }}>
            <div className="max-w-2xl mx-auto p-6 bg-white">
              <div className="flex items-center gap-2 mb-4">
                {/* <Calendar className="w-5 h-5 text-blue-600" /> */}
                <h1 className="text-xl font-semibold">{timelineData[selectedIdx]?.event_name}</h1>
                <span className="ml-auto text-gray-500 text-sm">{email_details?.date}</span>
              </div>

              <div className="space-y-3 mb-6">
                <div className="flex">
                  <span className="w-20 text-gray-600">From:</span>
                  <span>{email_details?.from_address ? email_details?.from_address : '-'}</span>
                </div>
                <div className="flex">
                  <span className="w-20 text-gray-600">To:</span>
                  <span>{email_details?.to_address ? email_details?.to_address : '-'}</span>
                </div>
                <div className="flex">
                  <span className="w-20 text-gray-600">CC:</span>
                  <span>{email_details?.cc_address !== '' ? email_details?.cc_addres : '-'}</span>
                </div>
                <div className="flex">
                  <span className="w-20 text-gray-600">BCC:</span>
                  <span>{email_details?.bcc_address ? email_details?.bcc_address : '-'}</span>
                </div>
                <div className="flex">
                  <span className="w-20 text-gray-600">Subject:</span>
                  <span>{email_details?.subject ? email_details?.subject : '-'}</span>
                </div>
              </div>
              <div className="space-y-4">
                Description:
                <FrameWrapper html={email_details?.body} />
              </div>
              {email_tag === 'zendesk' ? (
                <>
                  <div className="space-y-4 mt-6 mb-2 underline">Zendesk information:</div>
                  <li className="text-sm">
                    <strong className="font-medium">Zendesk Ticket ID: </strong>
                    {raw_data?.zendesk_ticket_id ? (
                      <a
                        href={`https://mytcare.zendesk.com/agent/tickets/${raw_data?.zendesk_ticket_id}`}
                        target="_blank"
                        className="text-blue-600 underline" rel="noreferrer"
                      >
                        {raw_data?.zendesk_ticket_id ? `${raw_data?.zendesk_ticket_id}` : '-'}
                      </a>
                    ) : (
                      '-'
                    )}
                  </li>
                  {raw_data?.zendesk_latest_comment ? (
                    <li className="text-sm">
                      <strong className="font-medium">Zendesk Latest Comment:</strong>{' '}
                      {raw_data?.zendesk_latest_comment}
                    </li>
                  ) : null}
                </>
              ) : null}
              {email_details?.calendar ? (
                <div className="flex items-center gap-2 mt-6 text-blue-600">
                  {/* <Download className="w-4 h-4" /> */}
                  <span className="text-sm">
                    <EventViewer icsString={email_details?.calendar} />
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        );
      }
    }
  };

  const getVisiblePages = () => {
    const start = Math.max(1, currentPage - 3);
    const length = Math.min(4, totalPages);
    return Array.from({ length }, (_, i) => start + i);
  };

  const renderPagination = () => {
    if (!loading) {
      return (
        <div className="flex items-center justify-center space-x-2 mt-4">
          <button
            onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded text-black disabled:bg-gray-100 disabled:text-gray-400 bg-white border border-gray-300 hover:bg-gray-50"
          >
            Previous
          </button>

          {getVisiblePages().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 rounded ${
                currentPage === page
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-black border border-gray-300 hover:bg-gray-50'
              }`}
            >
              {page}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            className="px-3 py-1 text-black rounded disabled:bg-gray-100 disabled:text-gray-400 bg-white border border-gray-300 hover:bg-gray-50"
          >
            Next
          </button>
        </div>
      );
    }
  };

  const renderSingleRow = (data, idx) => {
    const { event_name, timestamp, email_status, raw_data, email_details } = data;
    return (
      <div className="ct-row" id={raw_data?.email_tracking_uuid}>
        {/* <div className="ct-col ct-sm-font">{event_name}</div> */}
        <div className="ct-col ct-sm-font relative">
          {event_name || email_details?.subject}
          <br />
          {data?.email_tag === 'zendesk' ? (
            <span className="bg-gray-600  text-white top-0 left-0 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">
              Zendesk
            </span>
          ) : (
            <span className="bg-green-100 text-green-950 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">
              Transactional
            </span>
          )}
        </div>
        <div className="ct-col ct-sm-font">{email_status}</div>
        <div className="ct-col ct-sm-font">
          {timestamp !== '' ? (
            <>
              {moment(timestamp).tz(iana_timezone).format(day_format_full_year)}{' '}
              {moment(timestamp).tz(iana_timezone).format(time_format)}
            </>
          ) : (
            'NA'
          )}
        </div>
        <div className="ct-col ct-sm-font ct-si" onClick={() => toggleShowEmail(idx)}>
          <img
            src="https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1729674308/website/eye_1.svg"
            style={{ width: '26px', cursor: 'pointer' }}
          />
        </div>
        <div
          className="ct-col ct-sm-font ct-si"
          style={{ cursor: 'pointer' }}
          onClick={() => toggleEmailTrack(idx)}
        >
          <img src="https://images.myyogateacher.com/icons/ic_info_blue.png" />
        </div>
        <div className="ct-col ct-sm-font" onClick={() => toggleRawData(idx)}>
          <button className="text-gray-600 hover:text-gray-800 bg-transparent border-transparent">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  const renderData = () => {
    if (loading) {
      return <CustomLoading />;
    }
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-sm">Event name</div>
          <div className="ct-col ct-sm">Email status</div>
          <div className="ct-col ct-sm">Created date</div>
          <div className="ct-col ct-sm">View Email</div>
          <div className="ct-col ct-sm">Tracking details</div>
          <div className="ct-col ct-sm">Raw data</div>
        </div>
        {timelineData.length !== 0 && timelineData.map(renderSingleRow)}
      </div>
    );
  };

  const setNotificationVariable = (show, type, message) => {
    dispatch(actions.set_notification_variable(show, type, message));
  };

  return (
    <CommonHeader loading={loading} title={pageTitle} show_back>
      {renderData()}
      {renderPagination()}
      <CustomModal show={showStatusLog} close={toggleEmailTrack}>
        {renderEmailTrack()}
      </CustomModal>
      <CustomModal show={showRawData} close={toggleRawData}>
        {renderRawData()}
      </CustomModal>
      <CustomModal show={showEmail} close={toggleShowEmail}>
        {renderEmailView()}
      </CustomModal>
    </CommonHeader>
  );
}

export default EmailTimeline;
