import React, { Component } from 'react';
import CustomLoading from '../../../util_components/custom_loading';
import CustomInput from '../../../util_components/custom_input';
import CustomCheckbox from '../../../util_components/custom_checkbox';
import CustomRadioButton from '../../../util_components/custom_radio_button';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import CustomModal from '../../../util_components/custom_modal';
import { image_error_replace, chunk_array } from '../../../../util_functions';
import {
  onboarding_status,
  genders,
  marital_status,
  yoga_levels,
  min_list,
  lead_in_hours,
  StudentType
} from '../../../../constants';
import { post_api, put_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import './edit_teacher_profile.css';

export default class EditTeacherProfile extends Component {
  state = {
    teacher_details: {},
    loading: true,
    password: '',
    goals_error: false,
    add_certificate: false,
    edit_certificate: false,
    edit_certificate_index: 0,
    cert_name: '',
    cert_start: '',
    cert_end: '',
    teacher_adjective: '',
    add_adjective: false,
    fitness_goals_error: false,
    rebook_slots_within_12h: null
  };

  componentDidMount() {
    this.setState({
      teacher_details: {
        ...this.props.teacher_details,
        yoga_types_teaching: this.props.teacher_details.yoga_types_teaching
          ? this.props.teacher_details.yoga_types_teaching
          : []
      },
      loading: false
    });
  }

  toggle_add_certificate = () => this.setState({ add_certificate: !this.state.add_certificate });

  close_add_certificate = () =>
    this.setState({
      cert_name: '',
      cert_start: '',
      cert_end: '',
      add_certificate: false,
      edit_certificate: false,
      edit_certificate_index: 0,
      add_adjective: false,
      teacher_adjective: ''
    });

  submit_certificate = () => {
    const cert = {
      name: this.state.cert_name,
      start_date: this.state.cert_start,
      end_date: this.state.cert_end
    };
    const certificates = [...this.state.teacher_details.certificates];
    if (!this.state.edit_certificate) {
      certificates.push(cert);
      this.setState({ teacher_details: { ...this.state.teacher_details, certificates } }, () => {
        this.close_add_certificate();
      });
    } else {
      const index = this.state.edit_certificate_index;
      const updatedCertificates = [
        ...certificates.slice(0, index),
        cert,
        ...certificates.slice(index + 1)
      ];
      this.setState(
        { teacher_details: { ...this.state.teacher_details, certificates: updatedCertificates } },
        () => {
          this.close_add_certificate();
        }
      );
    }
  };

  delete_certificate = (index) => {
    const certificates = [...this.state.teacher_details.certificates];
    const updatedCertificates = [...certificates.slice(0, index), ...certificates.slice(index + 1)];
    this.setState({
      teacher_details: { ...this.state.teacher_details, certificates: updatedCertificates }
    });
  };

  handle_change = (e) => {
    this.setState({
      teacher_details: {
        ...this.state.teacher_details,
        [e.target.name]: e.target.value
      }
    });
  };

  handle_date_change = (key, val) => {
    this.setState({ [key]: val });
  };

  change_dob = (val) => {
    this.setState({ teacher_details: { ...this.state.teacher_details, date_of_birth: val } });
  };

  handle_cert_change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCSChange = (type) => {
    const { teacher_details } = this.state;
    let newValue;

    // If "all" is currently selected and a type is clicked, set to the other type
    if (teacher_details.rebook_slots_within_12h === 'all') {
      newValue = type === StudentType.Trial ? StudentType.Member : StudentType.Trial;
    }
    // If the same type is clicked again, set to null (none selected)
    else if (teacher_details.rebook_slots_within_12h === type) {
      newValue = null;
    }
    // If one type is clicked and the other is already selected, set to "all"
    else if ((type === StudentType.Trial && teacher_details.rebook_slots_within_12h === StudentType.Member) ||
             (type === StudentType.Member && teacher_details.rebook_slots_within_12h === StudentType.Trial)) {
      newValue = 'all';
    }
    // Otherwise set to the clicked type
    else {
      newValue = type;
    }

    this.setState({
      teacher_details: {
        ...teacher_details,
        rebook_slots_within_12h: newValue
      }
    });
  };

  handle_password = (e) => {
    this.setState({ password: e.target.value });
  };

  on_edit_certificate = (index) => {
    const cert = this.state.teacher_details.certificates[index];
    this.setState({
      edit_certificate: true,
      add_certificate: true,
      cert_name: cert.name,
      cert_start: cert.start_date,
      cert_end: cert.end_date,
      edit_certificate_index: index
    });
  };

  render_certificates = () => {
    const { certificates } = this.state.teacher_details;
    return (
      <div>
        {certificates.map((cert, index) => (
          <div className="edit-cert-cont" key={cert.name + index}>
            <h5>
              {index + 1}. {cert.name}
            </h5>
            <p>Start Date: {cert.start_date}</p>
            <p>End Date: {cert.end_date}</p>
            <div className="edit-cert-ed-del">
              <span className="edit-cert-ed" onClick={() => this.on_edit_certificate(index)}>
                Edit
              </span>
              <span className="edit-cert-del" onClick={() => this.delete_certificate(index)}>
                Delete
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  edit_goals = (item, item_index) => {
    const array = [...this.state.teacher_details?.goals];
    if (item_index < 0) {
      if (array.length >= 3) {
        this.setState({ goals_error: true });
      } else {
        array.push(item);
        this.setState({
          teacher_details: {
            ...this.state.teacher_details,
            goals: [...array]
          },
          goals_error: false
        });
      }
    } else {
      array.splice(item_index, 1);
      this.setState({
        teacher_details: {
          ...this.state.teacher_details,
          goals: [...array]
        },
        goals_error: false
      });
    }
  };

  edit_fitness_goals = (item, item_index) => {
    const array = [...(this.state.teacher_details?.goals_for_1on1_onboarding || [])];
    if (item_index < 0) {
      if (array.length >= 4) {
        this.setState({ fitness_goals_error: true });
      } else {
        array.push(item);
        this.setState({
          teacher_details: {
            ...this.state.teacher_details,
            goals_for_1on1_onboarding: [...array]
          },
          fitness_goals_error: false
        });
      }
    } else {
      array.splice(item_index, 1);
      this.setState({
        teacher_details: {
          ...this.state.teacher_details,
          goals_for_1on1_onboarding: [...array]
        },
        fitness_goals_error: false
      });
    }
  };

  edit_schools = (item, item_index) => {
    const array = [...this.state.teacher_details.yoga_schools];
    if (item_index < 0) {
      // if (array.length >= 3) {
      // 	this.setState({ goals_error: true });
      // } else {

      // }
      array.push(item);
      this.setState({
        teacher_details: {
          ...this.state.teacher_details,
          yoga_schools: [...array]
        },
        goals_error: false
      });
    } else {
      array.splice(item_index, 1);
      this.setState({
        teacher_details: {
          ...this.state.teacher_details,
          yoga_schools: [...array]
        },
        goals_error: false
      });
    }
  };

  remove_older_school = (school) => {
    const array = [...this.state.teacher_details.yoga_schools];
    const school_index = array.indexOf(school);
    array.splice(school_index, 1);
    this.setState({
      teacher_details: {
        ...this.state.teacher_details,
        yoga_schools: [...array]
      },
      goals_error: false
    });
  };

  remove_older_goal = (goal) => {
    const array = [...this.state.teacher_details.goals];
    const goal_index = array.indexOf(goal);
    array.splice(goal_index, 1);
    this.setState({
      teacher_details: {
        ...this.state.teacher_details,
        goals: [...array]
      },
      goals_error: false
    });
  };

  render_older_schools = (schools) => (
    <div>
      <div className="edit-older-goals-title">Schools not in list:</div>
      <div className="edit-older-goals-cont">
        {schools.map((school, i) => (
          <div className="edit-older-goals-single" key={i}>
            {school}
            <img
              src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
              onClick={() => this.remove_older_school(school)}
              alt="x"
            />
          </div>
        ))}
      </div>
    </div>
  );

  render_older_goals = (goals) => (
    <div>
      <div className="edit-older-goals-title">Goals not in list:</div>
      <div className="edit-older-goals-cont">
        {goals.map((goal, i) => (
          <div className="edit-older-goals-single" key={i}>
            {goal}
            <img
              src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
              onClick={() => this.remove_older_goal(goal)}
              alt="x"
            />
          </div>
        ))}
      </div>
    </div>
  );

  render_yoga_schools = () => {
    const { yoga_schools } = this.props.config_values;
    const chunked_schools = chunk_array(yoga_schools, 3);
    const user_schools = this.state.teacher_details.yoga_schools;
    return (
      <table>
        <tbody>
          {chunked_schools.map((arrays, index) => (
            <tr key={index}>
              {arrays.map((item, new_index) => {
                const item_index = user_schools.indexOf(item);
                return (
                  <td key={new_index}>
                    <div className="profile-yoga-goals">
                      <CustomCheckbox
                        label={item}
                        checked={item_index > -1}
                        onClick={() => this.edit_schools(item, item_index)}
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render_goals = () => {
    const teacher_goals_1 = this.props.config_values?.yoga_goals;
    const chunked_goals = chunk_array(teacher_goals_1, 3);
    const user_goals = this.state.teacher_details?.goals;
    return (
      <table>
        <tbody>
          {chunked_goals.map((arrays, index) => (
            <tr key={index}>
              {arrays.map((item, new_index) => {
                const item_index = user_goals.indexOf(item);
                return (
                  <td key={new_index}>
                    <div className="profile-yoga-goals">
                      <CustomCheckbox
                        label={item}
                        checked={item_index > -1}
                        onClick={() => this.edit_goals(item, item_index)}
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render_one_on_one_fitness_goals = () => {
    const teacher_goals_1 = this.props.config_values?.goals_for_1on1_fitness || [];
    const chunked_goals = chunk_array(teacher_goals_1, 4);
    const user_goals = this.state.teacher_details?.goals_for_1on1_onboarding || [];
    return (
      <table>
        <tbody>
          {chunked_goals.map((arrays, index) => (
            <tr key={index}>
              {arrays.map((item, new_index) => {
                const item_index = user_goals.indexOf(item);
                return (
                  <td key={new_index}>
                    <div className="profile-yoga-goals">
                      <CustomCheckbox
                        label={item}
                        checked={item_index > -1}
                        onClick={() => this.edit_fitness_goals(item, item_index)}
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onYogaClick = (type, level) => {
    const yoga_types = this.state.teacher_details.yoga_types_teaching;
    const obj = yoga_types.find((o) => o.name === type);
    if (typeof obj === 'undefined') {
      yoga_types.push({ name: type, level });
    } else {
      obj.level = level;
    }
    this.setState({
      teacher_details: {
        ...this.state.teacher_details,
        yoga_types_teaching: [...yoga_types]
      }
    });
  };

  on_yoga_check_box_click = (type, checked) => {
    if (checked) {
      const yoga_types = this.state.teacher_details.yoga_types_teaching;
      const filteredTypes = yoga_types.filter((item) => item.name !== type);
      this.setState({
        teacher_details: {
          ...this.state.teacher_details,
          yoga_types_teaching: [...filteredTypes]
        }
      });
    } else {
      this.onYogaClick(type, 'Intermediate');
    }
  };

  render_yoga_types = () => {
    const all_yoga_types = this.props.config_values.yoga_forms;
    const yoga_types = this.state.teacher_details.yoga_types_teaching;
    return (
      <table>
        <tbody>
          {all_yoga_types.map((type, index) => {
            const obj = yoga_types.find((o) => o.name === type);
            const selected_type = typeof obj !== 'undefined';
            return (
              <tr key={index + type}>
                <td>
                  <CustomCheckbox
                    label={type}
                    checked={selected_type}
                    onClick={() => this.on_yoga_check_box_click(type, selected_type)}
                  />
                </td>
                {yoga_levels.map((level, index) => {
                  const selected = typeof obj === 'undefined' ? false : obj.level === level;
                  return (
                    <td key={index + level}>
                      <CustomRadioButton
                        label={level}
                        onRadioClick={() => this.onYogaClick(type, level)}
                        selected={selected}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  save_profile = () => {
    const { teacher_details } = this.state;
    const update_teacher_details = {
      teacher_uuid: teacher_details.uuid,
      first_name: teacher_details.first_name,
      middle_name: teacher_details.middle_name,
      last_name: teacher_details.last_name,
      phone_personal: teacher_details.phone_personal,
      onboarding_status: teacher_details.status,
      gender: teacher_details.gender,
      marital_status: teacher_details.marital_status,
      date_of_birth: teacher_details.date_of_birth,
      street_address: teacher_details.street,
      goals: teacher_details.goals,
      goals_for_1on1_onboarding: teacher_details.goals_for_1on1_onboarding,
      city: teacher_details.city,
      state: teacher_details.state,
      zipcode: teacher_details.zipcode,
      stories: teacher_details.stories,
      intro_to_students: teacher_details.intro_to_students,
      video_url: teacher_details.video_url,
      video_thumbnail: teacher_details.video_thumbnail,
      vishesh_teacher: teacher_details.vishesh_teacher,
      yoga_schools: teacher_details.yoga_schools,
      is_yoga_consult_teacher: teacher_details.is_yoga_consult_teacher,
      opt_in_1on1_sessions: teacher_details.opt_in_1on1_sessions,
      trial_1st_1on1_only: teacher_details?.trial_1st_1on1_only,
      opt_in_yoga_with_friends_sessions: teacher_details.opt_in_yoga_with_friends_sessions,
      opt_in_group_classes: teacher_details.opt_in_group_classes,
      opt_in_community_classes: teacher_details.opt_in_community_classes,
      opt_in_concierge: teacher_details.opt_in_concierge,
      test_user: teacher_details.test_user,
      concierge_per_day_limit: teacher_details.concierge_per_day_limit,
      therapeautic: teacher_details.therapeautic,
      concierge_signature_image: teacher_details.concierge_signature_image,
      tag_line: teacher_details.tag_line,
      teacher_adjectives: teacher_details.teacher_adjectives.join(','),
      iana_timezone: teacher_details.iana_timezone,
      is_consultant_therapist: teacher_details.is_consultant_therapist,
      is_back_pain_therapist: teacher_details.is_back_pain_therapist,
      is_consultant_therapist_in_preferred_group:
        teacher_details.is_consultant_therapist_in_preferred_group,
      lead_time_backpain_consult: teacher_details.lead_time_backpain_consult,
      booking_lead_in_hours: Number(teacher_details.booking_lead_in_hours),
      booking_lead_in_minutes: Number(teacher_details.booking_lead_in_minutes),
      hide_from_onboarding: teacher_details.hide_from_onboarding,
      rebook_slots_within_12h: teacher_details.rebook_slots_within_12h
    };
    if (this.state.password !== '') {
      update_teacher_details.password = this.state.password;
    }
    this.setState({ loading: true }, () => {
      const url = get_api_url('update_teacher');
      post_api(url, update_teacher_details, true).then(() => {
        const data = {
          teacher_uuid: teacher_details.uuid,
          yoga_types_teaching: teacher_details.yoga_types_teaching,
          certificates: teacher_details.certificates,
          years_of_yoga_practise: teacher_details.years_of_yoga_practise,
          years_of_yoga_teaching_experience: teacher_details.years_of_yoga_teaching_experience
        };
        const yoga_url = get_api_url('update_yoga_details');
        post_api(yoga_url, data, true).then(() => {
          this.props.close_edit();
        });
      });
    });
  };

  on_box_click = (name, val) => {
    const new_val = val === 1 ? 0 : 1;
    this.setState({
      teacher_details: {
        ...this.state.teacher_details,
        [name]: new_val
      }
    });
  };

  del_story = (index) => {
    const { teacher_details } = this.state;
    const stories = [...teacher_details.stories];
    stories.splice(index, 1);
    this.setState({ teacher_details: { ...this.state.teacher_details, stories } });
  };

  render_stories = () => {
    const { teacher_details } = this.state;
    if (!!teacher_details.stories && teacher_details.stories.length > 0) {
      return (
        <div style={{ margin: '19px 0' }}>
          Stories:
          {teacher_details.stories.map((story, index) => (
            <div key={story.title} className="teacher-story-con">
              <div className="teacher-story-img">
                <img src={story.image} />
              </div>
              <div className="teacher-story-edit-content">
                <div>{story.title}</div>
                <div className="teacher-story-link">
                  <a href={story.url} target="_blank" rel="noopener noreferrer">
                    {story.url}
                  </a>
                </div>
              </div>
              <div className="teacher-story-del-icon" onClick={() => this.del_story(index)}>
                <img src="https://images.myyogateacher.com/teacher_web_app/ic_delete_red.png" />
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  on_number_change = (e) =>
    this.setState({
      teacher_details: {
        ...this.state.teacher_details,
        [e.target.name]: parseInt(e.target.value)
      }
    });

  on_time_change = (e) => {
    if (e.target.value >= 1 && e.target.value <= 12) {
      this.setState({
        teacher_details: {
          ...this.state.teacher_details,
          [e.target.name]: parseInt(e.target.value)
        }
      });
    }
  };

  remove_adj = (adj) => {
    const { teacher_details } = this.state;
    const array = [...teacher_details.teacher_adjectives];
    const adj_index = array.indexOf(adj);
    array.splice(adj_index, 1);
    this.setState({
      teacher_details: {
        ...teacher_details,
        teacher_adjectives: [...array]
      }
    });
  };

  add_adjective = () => this.setState({ add_adjective: true });

  render_adjectives = () => {
    const { teacher_details } = this.state;
    return (
      <div>
        <div className="edit-title">
          Teacher Adjectives:
          <button className="edit-add-cert-but" onClick={this.add_adjective}>
            Add Adjective
          </button>
        </div>
        <div className="edit-adj">
          {teacher_details.teacher_adjectives.map((t, i) => (
            <div className="edit-older-goals-single edit-adj-single" key={i}>
              {t}
              <img
                src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
                onClick={() => this.remove_adj(t)}
                alt="x"
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  save_adjective = () => {
    const { teacher_details, teacher_adjective } = this.state;
    const new_teacher_details = {
      ...teacher_details,
      teacher_adjectives: [...teacher_details.teacher_adjectives, teacher_adjective]
    };
    this.setState({ teacher_details: new_teacher_details });
    this.close_add_certificate();
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  handleUnhideFronNewStudent = (details) => {
    this.on_box_click('trial_1st_1on1_only', details?.trial_1st_1on1_only);

    if (details?.hide_from_new_students === 1) {
      const url = get_api_url(
        `/v2/recommendations/care/teachers/${details.uuid}/unhide_from_new_students`,
        null,
        true
      );
      put_api(url, null, true)
        .then((res) => {
          this.setState({
            teacher_details: {
              ...this.state.teacher_details,
              hide_from_new_students: 0
            }
          });
        })
        .catch((e) => {
          alert(e);
        });
    }
  };

  handleHideFromNewStudent = (details) => {
    if (details?.hide_from_new_students) {
      const url = get_api_url(
        `/v2/recommendations/care/teachers/${details.uuid}/unhide_from_new_students`,
        null,
        true
      );
      put_api(url, null, true)
        .then((res) => {
          this.setState({
            teacher_details: {
              ...this.state.teacher_details,
              hide_from_new_students: 0
            }
          });
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      const url = get_api_url(
        `/v2/recommendations/care/teachers/${details.uuid}/hide_from_new_students`,
        null,
        true
      );
      put_api(url, null, true)
        .then((res) => {
          this.setState({
            teacher_details: {
              ...this.state.teacher_details,
              hide_from_new_students: 1,
              trial_1st_1on1_only: 0
            }
          });
        })
        .catch((e) => {
          alert(e);
        });
    }
  };

  render() {
    const details = this.state.teacher_details;
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const user_goals = this.state.teacher_details.goals;
    const user_fitness_goals = this.state.teacher_details.goals_for_1on1_onboarding;
    const teacher_defined_goals = this.props.config_values.yoga_goals;
    const teacher_defined_fitness_goals = this.props.config_values.goals_for_1on1_fitness;
    const user_schools = this.state.teacher_details.yoga_schools;
    const fixed_schools = this.props.config_values.yoga_schools;
    const older_goals = user_goals.filter((goal) => teacher_defined_goals.indexOf(goal) === -1);
    const older_fitness_goals = user_fitness_goals?.filter(
      (goal) => teacher_defined_fitness_goals.indexOf(goal) === -1
    );
    const older_schools = user_schools.filter((school) => fixed_schools.indexOf(school) === -1);
    let iana_timezones = [];
    try {
      iana_timezones = this.props.config_values.teacher_timezones.map((t) => ({
        value: t,
        label: t
      }));
    } catch (e) {}
    return (
      <div className="common-page-container">
        <CustomModal show={this.state.add_adjective} close={this.close_add_certificate}>
          <CustomInput
            label="Add Adjective"
            onChange={this.onCommonChange}
            value={this.state.teacher_adjective}
            name="teacher_adjective"
            style={{ width: '100%' }}
          />
          <button onClick={this.save_adjective}>Save</button>
        </CustomModal>
        <CustomModal show={this.state.add_certificate} close={this.close_add_certificate}>
          <h4>Add certificate</h4>
          <CustomInput
            label="Name"
            value={this.state.cert_name}
            name="cert_name"
            onChange={this.handle_cert_change}
            style={{ width: '100%' }}
          />
          <CustomDatePicker
            label="Start Date"
            value={this.state.cert_start}
            handleChange={(val) => this.handle_date_change('cert_start', val)}
          />
          <CustomDatePicker
            label="End Date"
            value={this.state.cert_end}
            handleChange={(val) => this.handle_date_change('cert_end', val)}
            minDate={this.state.cert_start}
          />
          <button onClick={this.submit_certificate}>Save</button>
          <div style={{ height: '200px' }} />
        </CustomModal>
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.props.close_edit}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Edit Profile</div>
        </div>
        <div className="edit-profile-details">
          <div className="edit-profile-profile-pic">
            <img src={details.profile_photo} alt="pic" onError={image_error_replace} />
            {details.email}
            <div style={{ margin: '10px 0' }}>
              <button onClick={this.save_profile}>Save Profile</button>
            </div>
          </div>

          <div className="edit-profile-personal-details">
            <div className="edit-profile-single-detail">
              <CustomInput
                label="First name"
                value={details.first_name}
                name="first_name"
                onChange={this.handle_change}
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Middle name"
                onChange={this.handle_change}
                value={details.middle_name}
                name="middle_name"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Last name"
                onChange={this.handle_change}
                value={details.last_name}
                name="last_name"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Password"
                style={{ width: '300px' }}
                onChange={this.handle_password}
                value={this.state.password}
                name="password"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Phone"
                style={{ width: '300px' }}
                onChange={this.handle_change}
                value={details.phone_personal}
                name="phone_personal"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomSingleSelect
                label="Onboarding status"
                options={onboarding_status}
                onChange={this.handle_change}
                value={details.status}
                name="status"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomSingleSelect
                label="Iana Timezone"
                options={iana_timezones}
                onChange={this.handle_change}
                value={details.iana_timezone}
                name="iana_timezone"
              />
            </div>
            <div className="flex felx-row justify-space w-full">
              <div className="flex flex-col">
                <div>Lead time for lifestyle</div>
                <div className="flex flex-row">
                  <div className="edit-profile-single-detail">
                    <CustomSingleSelect
                      label="Hours"
                      options={lead_in_hours}
                      onChange={this.handle_change}
                      value={details.booking_lead_in_hours}
                      name="booking_lead_in_hours"
                    />
                  </div>
                  <div className="edit-profile-single-detail">
                    <CustomSingleSelect
                      label="Minutes"
                      options={min_list}
                      onChange={this.handle_change}
                      value={details.booking_lead_in_minutes}
                      name="booking_lead_in_minutes"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex felx-row justify-space w-full">
              <div className="flex flex-col">
                <div>Canceled Slots &lt; 12hrs</div>
                <div className="edit-profile-checkbox-list-arr">
                    <CustomCheckbox
                        label="Open for trials"
                        checked={details?.rebook_slots_within_12h === StudentType.Trial || details?.rebook_slots_within_12h === 'all'}
                        onClick={() =>
                        this.handleCSChange(StudentType.Trial)
                        }
                    />
                    <CustomCheckbox
                        label="Open for members"
                        checked={details?.rebook_slots_within_12h === StudentType.Member || details?.rebook_slots_within_12h === 'all'}
                        onClick={() =>
                        this.handleCSChange(StudentType.Member)
                        }
                    />
                </div>
              </div>
            </div>
            <div className="edit-profile-yoga">
              <div className="edit-profile-checkbox-list">
                <h4>1-on-1 Sessions:</h4>
                <div className="edit-profile-checkbox-list-arr">
                  <CustomCheckbox
                    label="Lifestyle 1-on-1 Sessions"
                    checked={details.opt_in_1on1_sessions === 1}
                    onClick={() =>
                      this.on_box_click('opt_in_1on1_sessions', details.opt_in_1on1_sessions)
                    }
                  />
                  <CustomCheckbox
                    label="Back Pain Consultant"
                    checked={details.is_consultant_therapist === 1}
                    onClick={() =>
                      this.on_box_click('is_consultant_therapist', details.is_consultant_therapist)
                    }
                  />
                  <CustomCheckbox
                    label="Trial Users First 1-on-1 Session only"
                    checked={details?.trial_1st_1on1_only === 1}
                    onClick={() => this.handleUnhideFronNewStudent(details)}
                  />
                  <CustomCheckbox
                    label="Hide from Trial Users"
                    checked={details?.hide_from_new_students === 1}
                    onClick={() => this.handleHideFromNewStudent(details)}
                  />
                  <CustomCheckbox
                    label="Hide from Onboarding"
                    checked={details?.hide_from_onboarding === 1 || false}
                    onClick={() =>
                      this.on_box_click('hide_from_onboarding', details.hide_from_onboarding)
                    }
                  />
                </div>
              </div>

              <div className="edit-profile-checkbox-list">
                <h4>Group classes:</h4>
                <div className="edit-profile-checkbox-list-arr">
                  <CustomCheckbox
                    label="Opt In Group Classes"
                    checked={details.opt_in_group_classes === 1}
                    onClick={() =>
                      this.on_box_click('opt_in_group_classes', details.opt_in_group_classes)
                    }
                  />
                  <CustomCheckbox
                    label="Opt In YWF Sessions"
                    checked={details.opt_in_yoga_with_friends_sessions === 1}
                    onClick={() =>
                      this.on_box_click(
                        'opt_in_yoga_with_friends_sessions',
                        details.opt_in_yoga_with_friends_sessions
                      )
                    }
                  />
                  <CustomCheckbox
                    label="Opt In Community Sessions"
                    checked={details.opt_in_community_classes === 1}
                    onClick={() =>
                      this.on_box_click(
                        'opt_in_community_classes',
                        details.opt_in_community_classes
                      )
                    }
                  />
                </div>
              </div>
              <div className="edit-profile-checkbox-list">
                <h4>Back pain program:</h4>
                <div className="edit-profile-checkbox-list-arr">
                  <CustomCheckbox
                    label="Back Pain Coach"
                    checked={details.is_back_pain_therapist === 1}
                    onClick={() =>
                      this.on_box_click('is_back_pain_therapist', details.is_back_pain_therapist)
                    }
                  />
                  <CustomCheckbox
                    label="Lifestyle Consult Sessions"
                    checked={details.is_yoga_consult_teacher === 1}
                    onClick={() =>
                      this.on_box_click('is_yoga_consult_teacher', details.is_yoga_consult_teacher)
                    }
                  />
                  <CustomCheckbox
                    label="Lifestyle Therapeautic Teacher"
                    checked={details.therapeautic === 1}
                    onClick={() => this.on_box_click('therapeautic', details.therapeautic)}
                  />
                  <CustomCheckbox
                    label="Consultant therapist in preferred group"
                    checked={details.is_consultant_therapist_in_preferred_group === 1}
                    onClick={() =>
                      this.on_box_click(
                        'is_consultant_therapist_in_preferred_group',
                        details.is_consultant_therapist_in_preferred_group
                      )
                    }
                  />
                  <CustomInput
                    label="Lead time for backpain consult session"
                    onChange={this.on_time_change}
                    name="lead_time_backpain_consult"
                    value={details.lead_time_backpain_consult}
                    style={{ widht: '500px' }}
                    type="number"
                  />
                </div>
              </div>
              <div className="edit-profile-checkbox-list">
                <h4>Other:</h4>
                <div className="edit-profile-checkbox-list-arr">
                  <CustomCheckbox
                    label="Vishesh Teacher"
                    checked={details.vishesh_teacher === 1}
                    onClick={() => this.on_box_click('vishesh_teacher', details.vishesh_teacher)}
                  />
                </div>
              </div>
              <div className="edit-profile-checkbox-list">
                <h4>Concierge:</h4>
                <div className="edit-profile-checkbox-list-arr">
                  <CustomCheckbox
                    label="Opt In Concierge"
                    checked={details.opt_in_concierge === 1}
                    onClick={() => this.on_box_click('opt_in_concierge', details.opt_in_concierge)}
                  />
                  <CustomInput
                    label="Concierge per day limit"
                    onChange={this.on_number_change}
                    name="concierge_per_day_limit"
                    type="number"
                    value={details.concierge_per_day_limit}
                    style={{ width: '200px' }}
                  />
                  <CustomInput
                    label="Concierge Signature Image Url"
                    onChange={this.handle_change}
                    name="concierge_signature_image"
                    value={details.concierge_signature_image}
                    style={{ width: '500px' }}
                  />
                  <CustomInput
                    label="Tagline"
                    onChange={this.handle_change}
                    name="tag_line"
                    value={details.tag_line}
                    style={{ width: '500px' }}
                  />
                </div>
              </div>
            </div>
            <div className="edit-profile-single-detail">
              <CustomSingleSelect
                label="Gender"
                options={genders}
                onChange={this.handle_change}
                value={details.gender}
                name="gender"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomSingleSelect
                label="Marital status"
                options={marital_status}
                onChange={this.handle_change}
                value={details.marital_status}
                name="marital_status"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomDatePicker
                value={details.date_of_birth}
                handleChange={(val) => this.change_dob(val)}
                label="D.O.B"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Street address"
                style={{ width: '300px' }}
                onChange={this.handle_change}
                value={details.street}
                name="street"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="City"
                onChange={this.handle_change}
                value={details.city}
                name="city"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="State"
                onChange={this.handle_change}
                value={details.state}
                name="state"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Zipcode"
                onChange={this.handle_change}
                value={details.zipcode}
                name="zipcode"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Years of yoga Teaching"
                style={{ width: '300px' }}
                onChange={this.handle_change}
                value={details.years_of_yoga_teaching_experience}
                name="years_of_yoga_teaching_experience"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Years of yoga practice"
                style={{ width: '300px' }}
                onChange={this.handle_change}
                value={details.years_of_yoga_practise}
                name="years_of_yoga_practise"
              />
            </div>
            <div className="edit-profile-single-detail">
              {/* <CustomSingleSelect
									label="Consultant therapist in preferred group"
									options={is_consultant_therapist_in_preferred_group}
									onChange={this.handle_teacher_change}
									value={details.is_consultant_therapist_in_preferred_group}
									name="is_consultant_therapist_in_preferred_group"
								/> */}
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Video Url"
                style={{ width: '500px' }}
                onChange={this.handle_change}
                value={details.video_url}
                name="video_url"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Video Thumbnail Url"
                style={{ width: '500px' }}
                onChange={this.handle_change}
                value={details.video_thumbnail}
                name="video_thumbnail"
              />
            </div>
            <div className="edit-profile-yoga">
              Yoga types teaching:
              <div>{this.render_yoga_types()}</div>
            </div>
            <div className="edit-profile-yoga">
              Yoga Goals:{this.render_goals()}
              {older_goals.length ? this.render_older_goals(older_goals) : null}
              {this.state.goals_error ? (
                <div className="edit-error">*Maximum 3 goals can be selected</div>
              ) : null}
            </div>
            <div className="edit-profile-yoga">
              Yoga Goals for 1on1 fitness funnel:{this.render_one_on_one_fitness_goals()}
              {older_fitness_goals?.length ? this.render_older_goals(older_fitness_goals) : null}
              {this.state.fitness_goals_error ? (
                <div className="edit-error">*Maximum 4 goals can be selected</div>
              ) : null}
            </div>
            <div className="edit-profile-yoga">
              Yoga Schools:{this.render_yoga_schools()}
              {older_schools.length ? this.render_older_schools(older_schools) : null}
            </div>
            <div className="edit-profile-yoga">
              <div>Intro:</div>
              <textarea
                onChange={this.handle_change}
                value={details.intro_to_students}
                name="intro_to_students"
              />
            </div>
            <div className="edit-profile-yoga">
              Certificates:{' '}
              <button onClick={this.toggle_add_certificate} className="edit-add-cert-but">
                Add certificate
              </button>
              {this.render_certificates()}
              {this.render_stories()}
              {this.render_adjectives()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
