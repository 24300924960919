import React, { useState } from 'react';
import CommonHeader from '../../util_components/common_header';
import Button from '../../util_components/button';

const taxonomyRoute = [
  '/yoga-for-specific-goals',
  '/yoga-asana',
  '/yoga-for-everyone',
  '/yoga-for-specific-goals'
];

function InvalidateCache() {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const prodUrl = 'https://myyogateacher.com';
  const stagUrl = 'https://student-testenv3.myyogateacher.com';

  const handleSubmit = () => {
    setSubmitted(true);
    const baseUrl = url.includes(prodUrl) ? prodUrl : stagUrl;
    if (url.includes('/articles')) {
      fetch(`${baseUrl}/web-articles-ssr/abracadabra`, {
        method: 'GET',
        cache: 'no-store'
      })
        .then(() => {
          console.log('Cache cleared for articles');
          setUrl('');
        })
        .catch((err) => {
          console.error('Error clearing cache', JSON.stringify(err));
          setError('Error in clearning cache');
        });
    }

    if (url.includes('/yoga-teachers/')) {
      const teacherSlug = url.split('/yoga-teachers/')[1];
      fetch(`${baseUrl}/web-dashboard-ssr/clean-yt-cache`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ path: `/yoga-teachers/${teacherSlug}` })
      })
        .then(() => {
          console.log('Cache cleared for yoga teacher', teacherSlug);
          setUrl('');
        })
        .catch((err) => {
          console.error('Error clearing yoga teacher cache', err);
          setError('Error in clearning cache');
        });
    }

    taxonomyRoute.forEach((route) => {
      if (url.includes(route)) {
        fetch(`${baseUrl}/taxonomy-clean-cache`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ path: route })
        })
          .then(() => {
            console.log('Cache cleared for', route);
            setUrl('');
          })
          .catch((err) => {
            console.error('Error clearing taxonomy cache', err);
            setError('Error in clearning cache');
          });
      }
    });
  };

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  return (
    <CommonHeader title="Invalidate Cache">
      <div className="">
        <div className="flex flex-col space-y-2 w-80">
          <input
            type="text"
            placeholder="Enter a URL"
            value={url}
            onChange={handleChange}
            className={`border ${error ? 'border-red-500' : 'border-gray-300'}`}
          />
          {error && <div className="flex items-center text-red-500 text-sm">{error}</div>}
          <Button onClick={handleSubmit} disabled={!!error || !url} className="w-full">
            Submit
          </Button>
          {submitted && !error && (
            <div className="text-green-500 text-sm">URL submitted successfully!</div>
          )}
        </div>
      </div>
    </CommonHeader>
  );
}

export default InvalidateCache;
